import { useContext } from 'react'
import { Context as InsurancesContext, Insurance } from '../contexts/Insurances'

const useInsurance = (id: string): Insurance => {
  const { insurances } = useContext(InsurancesContext)
  const insurance = insurances.find((insurance) => insurance.id === id)
  return insurance
}

export default useInsurance
