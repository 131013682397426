 /* eslint-disable */
import React, { useState } from 'react'
import { Route, Switch, useRouteMatch, Redirect,NavLink } from 'react-router-dom'
import { useWallet } from 'use-wallet'
import styled from 'styled-components'
import chef from '../../assets/img/chef.png'
import Button from '../../../components/Button'
import Container from '../../../components/Container'
import Page from '../../../components/Page'
import PageHeader from '../../../components/PageHeader'
import Loader from '../../../components/Loader'
import Card from '../../../components/Card'
import CardTitle from '../../../components/CardTitle'
import CardContent from '../../../components/CardContent'
import useLod from '../../../hooks/useLod'
import StatusValue from '../../../components/StatusValue'
import useModal from '../../../hooks/useModal'
import useMyFarms from '../../../hooks/useMyFarms'
import WalletProviderModal from '../../../components/WalletProviderModal'
//import { ProposalEntry, StyledDescription, StyledState, StyledButton, StyledProposalContentInner } from "./components/Proposal";
import SeparatorGrid from "./SeparatorWithCSS";
import Box from "./BoxWithDisplay";
import Surface from "../../../components/Surface";
import Separator from "../../../components/Separator";

const MyPools: React.FC = () => {
  const { path } = useRouteMatch()
  const [page, setTab] = useState('MyPools')
  const [pendingTx, setPendingTx] = useState(false)
  const [onPresentWalletProviderModal] = useModal(<WalletProviderModal />)
  const lod = useLod()
  const { account }: { account: any; ethereum: any } = useWallet()
  
  const myPools = useMyFarms()

  const changeTab = (tab:any) =>{
    setTab(tab)
  }
  return (
    <StyledWrapper>
      <StyledShell>
        <StyledMenu>
          <Box display="grid" alignItems="center" row>
            <StyledSubjectInner>
              <StyledDescription>
                Protocol
              </StyledDescription>
              <StyledDescription>
                Type
              </StyledDescription>
              <StyledDescription>
                Reward APY (Boost)
              </StyledDescription>
              <StyledDescription>
                LP Token Staked
              </StyledDescription>
              <StyledDescription>
                Unclaimed INSURE
              </StyledDescription>
              <StyledDescription>
                Action
              </StyledDescription>
            </StyledSubjectInner>
          </Box>
        </StyledMenu>
      {myPools.length > 0 ? (
      <>
        {myPools.length === 1 && myPools[0] ==='nodata'?
        (  
        <div>
          <Separator />
          <CardContent>
            <Box display="grid" alignItems="center" row>
                <StyledLoadingWrapper>
                You have no farming pools
                </StyledLoadingWrapper>
            </Box>
          </CardContent>
        </div>
        ):
        (
          <>
            {myPools.map((insurance, j) => (
              <React.Fragment key={j}>
                <Separator />
                <CardContent>
                  <Box display="grid" alignItems="center" row>
                  <StyledSubjectInner>
                      <StyledProtocol >
                        <StyledImg src={require(`../../../assets/img/${insurance.icon}.png`)} />
                        <StyledActionSpacer/>
                        <StyledInfo >
                          {insurance.name}
                        </StyledInfo>
                      </StyledProtocol >      
                      <StyledInfo >
                        {insurance.type}
                      </StyledInfo>
                      <StyledInfo >
                      {`${insurance.apy.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} % (${insurance.working_balance.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} x)`}
                      </StyledInfo>
                      <StyledInfo >
                      {`${(insurance.staked/(10 ** insurance.tokenDecimal )).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}
                      </StyledInfo>
                      <StyledInfo >
                      {`${(insurance.unclaimed/(10 ** 18 )).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}
                      </StyledInfo>
                      <StyledButton>
                        <Button size={'sm'}to={`/underwriting/${insurance.id}`} text={'View'}/>
                          
                      </StyledButton>
                    </StyledSubjectInner>
                  </Box>
                </CardContent>
                </React.Fragment>
            ))}
        </>
        )}
      </>
      ):(
        <div>
        <Separator />
        <CardContent>
          <Box display="grid" alignItems="center" row>
              <StyledLoadingWrapper>
               <Loader/>
              </StyledLoadingWrapper>
          </Box>
        </CardContent>
        </div>
      )}
      </StyledShell>
    </StyledWrapper>
  )
}

interface StyledSeparatorProps {
  gridArea?: string;
}


export const StyledMenu = styled.div`
  padding-left: 16px;
  padding-right: 16px
`;

export const StyledShell = styled.div`
  min-width: 700px;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
`;

export const StyledWrapper = styled.div`
  display: block;
  overflow-x: scroll;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
`;

export const StyledSubjectInner = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 25fr 10fr 20fr 20fr 15fr 10fr;
  width:100%
  text-align: center;
  margin-bottom: 5px;
`;

export const StyledIcon = styled.div`
  text-align: left;
  vertical-align: middle;
`;
export const StyledButton = styled.div`
  display: grid;
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    align-items: flex-end;
  }
`;

export const StyledInfo = styled.span<StyledSeparatorProps>`
  display: inline-block;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
`;
export const StyledProtocol = styled.div<StyledSeparatorProps>`
  display: flex;
  align-items: center;
  justify-content: left;
`;


export const StyledDescription = styled.span`
  display: inline-block;
  align-items: center;
  text-align: center;
  font-size: 14px;
`;

const StyledLoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
`

const StyledImg = styled.img`
  alt: '🤔'
  height: 40px;
  width: 40px;
  align-items: left;
`
const StyledActionSpacer = styled.div`
  height: ${(props) => props.theme.spacing[2]}px;
  width: ${(props) => props.theme.spacing[3]}px;
`

export default MyPools
/*
        <Separator />
        <CardContent>
          <Box display="grid" alignItems="center" row>
            <StyledProposalContentInner>
              <StyledInfo >
                <StyledSubject>StableCredit cover</StyledSubject>
                <StyledDetails>
                  <StyledDetail>Amt. 100 DAI</StyledDetail>
                  <StyledDetail>Val. 100 DAI</StyledDetail>
                  <StyledDetail>Earn. 100 DAI</StyledDetail>
                </StyledDetails>
              </StyledInfo>
              <StyledButton>
                <Button>
                  View
                </Button>
              </StyledButton>
            </StyledProposalContentInner>
          </Box>
        </CardContent>

{proposals && (
  <Surface>
    {proposals.map((prop, i) => {
      if (i == 0) {
        return <ProposalEntry key={prop.hash} prop={prop} onVote={onVote} onRegister={onRegister} />;
      } else {
        return [<Separator />, <ProposalEntry key={prop.hash} prop={prop} onVote={onVote} onRegister={onRegister} />];
      }
    })}
  </Surface>
)}

const MyPools: React.FC = () => {
  const { path } = useRouteMatch()
  const [page, setTab] = useState('MyPools')
  const [pendingTx, setPendingTx] = useState(false)
  const [onPresentWalletProviderModal] = useModal(<WalletProviderModal />)
  const lod = useLod()
  const { account }: { account: any; ethereum: any } = useWallet()
  

  const changeTab = (tab:any) =>{
    setTab(tab)
  }
  return (
    <div>
      <Separator />
      <CardContent>
        <Box display="grid" alignItems="center" row>
          <StyledProposalContentInner>
            <StyledDescription>StableCredit cover</StyledDescription>
            <SeparatorGrid orientation={"vertical"} stretch={true} gridArea={"spacer1"} />
            <StyledInfo　gridArea={"amount"} >100 DAI</StyledInfo>
            <SeparatorGrid orientation={"vertical"} stretch={true} gridArea={"spacer2"} />
            <StyledInfo　gridArea={"expire"} >12-Dec-2021</StyledInfo>
            <SeparatorGrid orientation={"vertical"} stretch={true} gridArea={"spacer3"} />
            <StyledInfo　gridArea={"state"} >Trading</StyledInfo>
            <SeparatorGrid orientation={"vertical"} stretch={true} gridArea={"spacer4"} />
        
            <StyledButton>
              <Button>
                Claim
              </Button>
            </StyledButton>
          </StyledProposalContentInner>
        </Box>
      </CardContent>
    </div>
  )
}

interface StyledSeparatorProps {
  gridArea?: string;
}

export const StyledProposalContentInner = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 30fr 5px 20fr 5px 20fr 5px 12fr 5px 18fr;
  grid-template-areas: "title spacer1 amount spacer2 expire spacer3 state spacer4 action";
  grid-template-rows: 100fr;
  @media (max-width: 768px) {
    flex-flow: column nowrap;
  }
`;


export const StyledButton = styled.div`
  display: grid;
  grid-area: action;
  margin-left: 10px;
  justify-content: center;
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    align-items: flex-end;
  }
`;

export const StyledDescription = styled.span`
  display: grid;
  grid-area: title;
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    align-items: flex-start;
  }
`;

export const StyledInfo = styled.span<StyledSeparatorProps>`
  margin-left: 5px;
  margin-right: 5px;
  display: grid;
  grid-area: ${(props) => props.gridArea};
  justify-content: center;
  min-width: 67px;
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    align-items: flex-start;
  }
`;
    <div>
      
      {myPools.length > 0 ? (
      <div>
        {myPools.length === 1 && myPools[0] ==='nodata'?
        (  
        <div>
          <Separator />
          <CardContent>
            <Box display="grid" alignItems="center" row>
                <StyledLoadingWrapper>
                You have no pool expoposures
                </StyledLoadingWrapper>
            </Box>
          </CardContent>
        </div>
        ):(
        <div>
          {myPools.map((insurance, j) => (
          <React.Fragment key={j}>
            <Separator />
            <CardContent>
              <Box display="grid" alignItems="center" row>
                <StyledProposalContentInner>
                  <StyledIcon>
                    <StyledImg src={require(`../../../assets/img/${insurance.icon}.png`)} />
                  </StyledIcon>
                  <StyledInfo >
                    <StyledSubject>{insurance.description}</StyledSubject>
                    <StyledDetails>
                      <StyledDetail>{`Amt: ${(insurance.balance /(10 ** insurance.tokenDecimal)).toLocaleString('en-US')} LP Token`}</StyledDetail>
                      <StyledDetail>{`Staked: ${(insurance.staked /(10 ** insurance.tokenDecimal)).toLocaleString('en-US')} LP Token`}</StyledDetail>
                      <StyledDetail>{`Val: $ ${(insurance.underlying /(10 ** insurance.tokenDecimal)).toLocaleString('en-US')}`}</StyledDetail>
                    </StyledDetails>
                  </StyledInfo>
                  <StyledButton>
                    <Button to={`/pools/${insurance.id}`}>
                      View
                    </Button>
                  </StyledButton>
                </StyledProposalContentInner>
              </Box>
            </CardContent>
          </React.Fragment>
          ))}
        </div>
        )}
      </div>
      ):(
        <div>
        <Separator />
        <CardContent>
          <Box display="grid" alignItems="center" row>
              <StyledLoadingWrapper>
               Loading.. <Loader/>
              </StyledLoadingWrapper>
          </Box>
        </CardContent>
        </div>
      )}
      
      
    </div>



*/