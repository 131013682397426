 /* eslint-disable */
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const Nav: React.FC = () => {
  return (
    <StyledNav>
      
      <StyledLink
        exact
        activeClassName="active"
        to="/insurances"
      >
        Buy Insurance
      </StyledLink>
      <StyledLink exact activeClassName="active" to="/underwriting">
        Underwriting
      </StyledLink>
      
      <StyledLink exact activeClassName="active" to="/dashboard">
        Dashboard
      </StyledLink>
      <StyledAbsoluteLink href="https://insuredao.gitbook.io/insuredao/" target="_blank">
        Docs
      </StyledAbsoluteLink>
    </StyledNav>
  )
}

const StyledNav = styled.nav`
  align-items: center;
  display: flex;
`

const StyledLink = styled(NavLink)`
  color: ${(props) => props.theme.color.white};
  font-weight: 700;
  padding-left: ${(props) => props.theme.spacing[3]}px;
  padding-right: ${(props) => props.theme.spacing[3]}px;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.color.grey[200]};
  }
  &.active {
    color: ${(props) => props.theme.color.primary.main};
  }
  @media (max-width: 400px) {
    padding-left: ${(props) => props.theme.spacing[2]}px;
    padding-right: ${(props) => props.theme.spacing[2]}px;
  }
`

const StyledAbsoluteLink = styled.a`
  color: ${(props) => props.theme.color.white};
  font-weight: 700;
  padding-left: ${(props) => props.theme.spacing[3]}px;
  padding-right: ${(props) => props.theme.spacing[3]}px;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.color.grey[200]};
  }
  &.active {
    color: ${(props) => props.theme.color.primary.main};
  }
  @media (max-width: 400px) {
    padding-left: ${(props) => props.theme.spacing[2]}px;
    padding-right: ${(props) => props.theme.spacing[2]}px;
  }
`

export default Nav


/*
<StyledLink exact activeClassName="active" to="/">
        Home
      </StyledLink>
      
      <StyledLink exact activeClassName="active" to="/dao">
        DAO
      </StyledLink>
*/