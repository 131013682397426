 /* eslint-disable */
import BigNumber from 'bignumber.js'
import React, { useCallback, useState,useEffect } from 'react'
import styled from 'styled-components'
import { useWallet } from 'use-wallet'
import { Contract } from 'web3-eth-contract'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import CardIcon from '../../../components/CardIcon'
import IconButton from '../../../components/IconButton'
import { AddIcon,RemoveIcon } from '../../../components/icons'
import Label from '../../../components/Label'
import StatusValue from '../../../components/StatusValue'
import useAllowance from '../../../hooks/useAllowance'
import useApprove from '../../../hooks/useApprove'
import useModal from '../../../hooks/useModal'
import useRequestWithdraw from '../../../hooks/useRequestWithdraw'
import useDeposit from '../../../hooks/useDeposit'
import useUnderlyingBalance from '../../../hooks/useUnderlyingBalance'
import useTokenBalance from '../../../hooks/useTokenBalance'
//import useEarnings from '../../../hooks/useEarnings'
import useWithdraw from '../../../hooks/useWithdraw'
import { getBalanceNumber,getDisplayBalance } from '../../../utils/formatBalance'
import StakeModal from './StakeModal'
import WithdrawModal from './WithdrawModal'
import useLod from '../../../hooks/useLod'
import useFarming from '../../../hooks/useFarming'
import useReward from '../../../hooks/useReward'
import useUnlock from '../../../hooks/useUnlock'
import useStatus from '../../../hooks/useStatus'
import { getMarketContract,getUnlock } from '../../../lod/utils'
import Countdown, { CountdownRenderProps } from 'react-countdown'
import { provider } from 'web3-core'

interface StakeProps {
  marketContract: Contract
  pool: any
}

const StakeLP: React.FC<StakeProps> = ({ marketContract, pool}) => {
  const [pendingTx, setPendingTx] = useState(false)
  const [requestedApproval, setRequestedApproval] = useState(false)
  const lod = useLod()
  const values = useFarming(pool.type, pool.pid) 
  const params = useStatus(pool.type, pool.pid) 

  const stakedBalance = new BigNumber(values.staked)
  const unclaimed = new BigNumber(values.unclaimed)
  const boost = new BigNumber(values.working_balance).gt(0)? new BigNumber(values.working_balance) :new BigNumber(1)
  const highAPY = new BigNumber(values.highAPY)
  const APY = new BigNumber(values.lowAPY).multipliedBy(boost)
  
  
  /*
  const [nextRebase, setNextRebase] = useState(0);
  const {account}: { account: string } = useWallet()
  const market = getMarketContract(lod,pool.pid)
  */
  
  const allowance = useAllowance(marketContract,pool.gaugeAddress)
  const { onApprove } = useApprove(marketContract,pool.gaugeAddress)
  
  const LPBalance = useTokenBalance(pool.marketAddress)
  const total = LPBalance.plus(stakedBalance)
  const stakedUnderlying = useUnderlyingBalance(pool.pid)
  //const leverage = values.working_balance !== new BigNumber(0) || 0? values.working_balance.dividedBy(unclaimed):new BigNumber(1)
  //console.log(leverage)
  //const earnings = useEarnings(pool.pid)  
  
  const now = Date.now()
  const unlock = useUnlock(pool.pid)
  const wait = (Number(unlock.timestamp) + Number(pool.lockup) )*1000

  
  //const withdrawableAmount = withdrawable? requested : 0
  const { onDeposit } = useDeposit(pool.pid, pool.tokenDecimal)
  const { onRequestWithdraw } = useRequestWithdraw(pool.pid,pool.tokenDecimal)
  const { onWithdraw } = useWithdraw(pool.pid,pool.tokenDecimal)
  const { onReward } = useReward(pool.pid)

  const [onPresentDeposit] = useModal(
    <StakeModal
      max={LPBalance}
      decimal={pool.tokenDecimal}
      onConfirm={onDeposit}
      unlock={unlock}
      params={params}
      tokenName={`LP Token`}
    />,
  )
  const [onPresentWithdraw] = useModal(
    <WithdrawModal
      max={stakedBalance}
      decimal={pool.tokenDecimal}
      onConfirm={onWithdraw}
      tokenName={`LP Token`}
    />,
  )
  
  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      
      const txHash = await onApprove()
      // user rejected tx or didn't go thru
      if (!txHash) {
        setRequestedApproval(false)
      }
    } catch (e) {
      console.log(e)
    }
  }, [onApprove, setRequestedApproval])

  return (
    <Card>
      <CardContent>
        <StyledCardContentInner>
        <StyledText>Farming</StyledText>
        <StyledDetail>Stake LP token to farm INSURE</StyledDetail>
          
          <StyledCardContent>
            <StyledLabel >Reward APY</StyledLabel >
            <StatusValue value={getBalanceNumber(APY,0)} decimals={2}　suffix={'%'}/>
          </StyledCardContent>
          <StyledCardContent>
            <StyledLabel >Your Boost</StyledLabel >
            <StatusValue value={getBalanceNumber(boost,0)}  decimals={2} suffix={'x'}/>
          </StyledCardContent>
          <StyledCardContent>
            <StyledLabel >LP Token Available</StyledLabel >
            <StatusValue value={getDisplayBalance(LPBalance,pool.tokenDecimal)} suffix={''}/>
          </StyledCardContent>
          <StyledCardContent>
            <StyledLabel >LP Token Staked</StyledLabel >
            <StatusValue value={getDisplayBalance(stakedBalance,pool.tokenDecimal)} suffix={''}/>
          </StyledCardContent>
          <StyledCardContent>
            <StyledLabel >Unclaimed INSURE</StyledLabel >
            <StatusValue value={getDisplayBalance(unclaimed,18)}  prefix={''}/>
          </StyledCardContent>
          
          
          <StyledCardActions>
            {!allowance.toNumber() ? (
              <Button
                disabled={requestedApproval}
                onClick={handleApprove}
                text={!requestedApproval ?`Approve LP Token`:`Approving..`}
              />
            ) : (
              <>
                <Button
                  text={'Stake'}
                  onClick={onPresentDeposit}
                />
                <StyledActionSpacer />
                <Button
                  text={'Unstake'}
                  disabled={stakedBalance.eq(new BigNumber(0))}　
                  onClick={onPresentWithdraw}
                />
                <StyledActionSpacer />
                <Button
                  disabled={unclaimed.eq(new BigNumber(0)) ||pendingTx}　
                  text={pendingTx ? 'Claiming..' : 'Claim'}
                  onClick={async () => {
                    setPendingTx(true)
                    await onReward()
                    setPendingTx(false)
                  }}
                />
                
              </>
            )}
          </StyledCardActions>
        </StyledCardContentInner>
      </CardContent>
    </Card>
  )
}

const StyledCardContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: inline;
  justify-content: space-between;
  width: 100%
`
const StyledCardActions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.theme.spacing[4]}px;
  width: 100%;
`

const StyledActionSpacer = styled.div`
  height: ${(props) => props.theme.spacing[4]}px;
  width: ${(props) => props.theme.spacing[4]}px;
`

const StyledCardContentInner = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`
const StyledLabel = styled.div`
  color: ${(props) => props.theme.color.grey[600]};
  font-size: h4
`

const StyledValue = styled.div`
  font-family: 'Roboto Mono', monospace;
  color: ${(props) => props.theme.color.grey[800]};
  font-size: h4;
  font-weight: 700;
  display: inline;
`

const StyledText = styled.div`
  align-items: center;
  color: ${props => props.theme.color.grey[900]};
  display: flex;
  font-size: 16px;
  font-weight: 700;
  height: 44px;
  justify-content: center;
  width:100%
`
const StyledDetail = styled.div`
  align-items: center;
  color: ${props => props.theme.color.grey[600]};
  display: flex;
  font-size: 14px;
  height: 34px;
  justify-content: center;
  width:100%
`

export default StakeLP

/*
          <StyledCardContent>
            <StyledLabel >Boosted APY</StyledLabel >
            <StatusValue value={getBalanceNumber(highAPY,0)} decimals={2}　suffix={'%'}/>
          </StyledCardContent>
*/