 /* eslint-disable */
import BigNumber from 'bignumber.js'
import React, { useCallback, useMemo, useState,useEffect } from 'react'
import styled from 'styled-components'
import Button from '../../../components/Button'
import Modal, { ModalProps } from '../../../components/Modal'
import ModalActions from '../../../components/ModalActions'
import ModalContent from '../../../components/ModalContent'
import ModalTitle from '../../../components/ModalTitle'
import TokenInput from '../../../components/TokenInput'
import { getFullDisplayBalance } from '../../../utils/formatBalance'
import { getDisplayBalance,getBalanceNumber } from '../../../utils/formatBalance'
import StatusValue from '../../../components/StatusValue'
import Countdown, { CountdownRenderProps } from 'react-countdown'
import useBlock from '../../../hooks/useBlock'
interface WithdrawRequestModalProps extends ModalProps {
  unstaked: any
  staked: any
  onRequest: (amount: string) => void
  onUnstake: (amount: string) => void
  unlock: any
  pool: any
  values: any
}

const WithdrawRequestModal: React.FC<WithdrawRequestModalProps> = ({
  unstaked,
  staked,
  onRequest,
  onUnstake,
  onDismiss,
  unlock,
  pool,
  values
}) => {
  const [requestVal, setRequestVal] = useState('')
  const [withdrawVal, setWithdtrawVal] = useState('')
  const [pendingRequest, setPendingRequest] = useState(false)
  const [pendingWithdraw, setPendingWithdraw] = useState(false)
  const block = useBlock()
  const renderer = (countdownProps: CountdownRenderProps) => {
    const { days ,hours, minutes, seconds } = countdownProps;
    const paddedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const paddedHours = hours < 10 ? `0${hours}` : hours;
    const paddedDays = days < 10 ? `0${days}` : days;
    return (
      <span>
        {paddedDays}:{paddedHours}:{paddedMinutes}:{paddedSeconds}
      </span>
    );
  };

  let now = Math.floor(Date.now() / 1000);
  let wait = (Number(unlock.timestamp) + Number(values.lockup) )*1000
  let limit = Number(values.withdrawGrace)*1000 + wait 
  let expired = now > (Number(values.withdrawGrace) + Number(unlock.timestamp))
  let withdrawable = now > Number(values.lockup) + Number(unlock.timestamp) && expired === false
  let requested = 0 < unlock.amount &&!expired　? new BigNumber(unlock.amount):new BigNumber(0) 
  let rate = values.exchange /1e18
  let max = requested
  let capacity = values.liquidity.minus(values.utilized).div(rate)
  let totalBalance = Number(unstaked) + Number(staked)
  
  useEffect(() => {
    wait = (Number(unlock.timestamp) + Number(values.lockup) )*1000
    limit = Number(values.withdrawGrace)*1000 + wait 
    expired = now > (Number(values.withdrawGrace) + Number(unlock.timestamp))
    requested = 0 < unlock.amount &&!expired　? new BigNumber(unlock.amount):new BigNumber(0) 
    rate = values.exchange /1e18
    max = requested
    withdrawable = now > Number(values.lockup) + Number(unlock.timestamp) && expired === false
    capacity = values.liquidity.minus(values.utilized).div(rate)
  }, [unlock, values, now])  

  let fullBalance = useMemo(() => {
    if(capacity.gt(max)){
      return getBalanceNumber(max,pool.tokenDecimal)
    }else{
      return getBalanceNumber(capacity,pool.tokenDecimal)
    }
  }, [max])
  

  const handleRequestChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setRequestVal(e.currentTarget.value)
    },
    [setRequestVal],
  )

  const handleSelectRequestMax = useCallback(() => {
    setRequestVal(getBalanceNumber(new BigNumber(unstaked),pool.tokenDecimal).toString())
  }, [fullBalance, setRequestVal])

  const handleWithdrawChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setWithdtrawVal(e.currentTarget.value)
    },
    [setWithdtrawVal],
  )

  const handleSelectWithdrawMax = useCallback(() => {
    setWithdtrawVal(fullBalance.toString())
  }, [fullBalance, setWithdtrawVal])

  return (
    <Modal>
      <ModalTitle text={`Withdraw collateral`} />
      
      <StyledDescription>To withdraw collateral, you need to request withdrawal first.
        <br/>There will be 7days queue to withdraw after your request.
        <br/>You can't request again once you requested.</StyledDescription>
      <ModalContent>
        <StyledActionSpacer />
        {!requested.eq(new BigNumber(0))?(
          <>
            {withdrawable?(
              <>
                <StyledGuide>STEP3: Withdraw</StyledGuide>          
                <StyledActionSpacer />  
                <StyledCardContent>
                  <StyledLabel >LP Token Requested</StyledLabel >
                  <StatusValue value={`${getDisplayBalance(requested, pool.tokenDecimal)} (≈$${getDisplayBalance(new BigNumber(Number(requested)*rate), pool.tokenDecimal)})`}  suffix={''}/>
                </StyledCardContent>
                <StyledCardContent>
                  <StyledLabel >Available to Withdrawal</StyledLabel >
                  <StatusValue value={`${getDisplayBalance(new BigNumber(fullBalance),0)} (≈$${getDisplayBalance(new BigNumber(Number(fullBalance)*rate), 0)})`}  suffix={''}/>
                </StyledCardContent>
                <StyledCardContent>
                  <StyledLabel >Time remaining</StyledLabel >
                  <StyledValue>
                    <Countdown
                      date={new Date(limit)}
                      renderer={renderer}
                    />
                  </StyledValue>
                </StyledCardContent>
                <StyledActionSpacer />
                <StyledNote>Your collateral can be withdrawn up to the amount not utilized in the pool.</StyledNote>
                <StyledActionSpacer />
                <StyledActionSpacer />
                <StyledActionSpacer />  
                <StyledActionWrapper>
                  <TokenInput
                    onSelectMax={handleSelectWithdrawMax}
                    onChange={handleWithdrawChange}
                    value={withdrawVal}
                    max={fullBalance}
                    symbol={'LP Token'}
                  />
                  <StyledActionSpacer />
                  <Button
                    disabled={pendingWithdraw||Number(withdrawVal) ===0}
                    text={pendingWithdraw ? 'Pending..' : 'Withdraw'}
                    onClick={async () => {
                      setPendingWithdraw(true)
                      await onUnstake(withdrawVal)
                      setPendingWithdraw(false)
                      onDismiss()
                    }}
                  />
                </StyledActionWrapper>
                <StyledText>{`You are about to withdraw ≈$${(Number(withdrawVal)*rate).toLocaleString()} of LP Token`}</StyledText>
              </>
            ):(
              <>
                <StyledGuide>STEP2: Queue</StyledGuide>
            
                <StyledActionSpacer />
                <StyledActionSpacer />  
                <StyledCardContent>
                  <StyledLabel >LP Token Requested</StyledLabel >
                  <StatusValue value={`${getDisplayBalance(requested, pool.tokenDecimal)} (≈$${getDisplayBalance(new BigNumber(Number(requested)*rate), pool.tokenDecimal)})`}  suffix={''}/>
                </StyledCardContent>
                <StyledCardContent>
                  <StyledLabel >Withdrawal wait</StyledLabel >
                  <StyledValue>
                    <Countdown
                      date={new Date(wait)}
                      renderer={renderer}
                    />
                  </StyledValue>
                </StyledCardContent>
                
              </>
            )}
            
          </>
        ):(
         <>
            <StyledGuide>STEP1: Request</StyledGuide>
            
            <StyledActionSpacer />
            <StyledActionSpacer />  
            <StyledCardContent>
              <StyledLabel >Available to Request</StyledLabel >
              <StatusValue value={`${getDisplayBalance(new BigNumber(unstaked), pool.tokenDecimal)} (≈$${getDisplayBalance(new BigNumber(unstaked*rate), pool.tokenDecimal)})`}  suffix={''}/>
            </StyledCardContent>
            <StyledActionSpacer />  
            <StyledNote>Your staked LP Token is not available unless unstake.</StyledNote>
            <StyledActionSpacer />
            <StyledActionSpacer />
            <StyledActionSpacer />
            <Button
                disabled={pendingRequest}
                text={pendingRequest ? 'Pending..' : 'Request'}
                onClick={async () => {
                  setPendingRequest(true)
                  await onRequest(getBalanceNumber(new BigNumber(unstaked),pool.tokenDecimal).toString())
                  setPendingRequest(false)
                  onDismiss()
                }}
              />
            </> 
        )}
        
      </ModalContent>
      <ModalActions>
        <Button text="Cancel" variant="secondary" onClick={onDismiss} />
      </ModalActions>
    </Modal>
  )
}

export default WithdrawRequestModal

const StyledGuide = styled.div`
  align-items: center;
  color: ${props => props.theme.color.grey[700]};
  display: flex;
  font-size: 14px;
  font-weight: 700;
  height: 44px;
  justify-content: center;
`

const StyledText = styled.div`
  align-items: center;
  color: ${props => props.theme.color.grey[400]};
  display: flex;
  font-size: 14px;
  font-weight: 700;
  height: 44px;
  justify-content: center;
`
const StyledDescription = styled.div`
  align-items: center;
  color: ${props => props.theme.color.grey[400]};
  display: flex-start;
  font-weight: 700;
  font-size: 14px;
  padding-left: ${(props) => props.theme.spacing[4]}px;
  padding-right: ${(props) => props.theme.spacing[4]}px;
  justify-content: center;
`

const StyledNote = styled.div`
  align-items: center;
  color: ${props => props.theme.color.grey[400]};
  display: flex-start;
  font-weight: 700;
  font-size: 14px;
  justify-content: center;
`

const StyledActionWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 80fr 2fr 18fr;
  grid-template-areas: "info action";
  grid-template-rows: 100fr;
  
  @media (max-width: 768px) {
    flex-flow: column nowrap;
  }
`;

const StyledActionSpacer = styled.div`
  height: ${(props) => props.theme.spacing[2]}px;
  width: ${(props) => props.theme.spacing[2]}px;
`
const StyledCardContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: inline;
  justify-content: space-between;
  width: 100%
`
const StyledLabel = styled.div`
  color: ${(props) => props.theme.color.grey[600]};
  font-size: h4
`

const StyledValue = styled.div`
  font-family: 'Roboto Mono', monospace;
  color: ${(props) => props.theme.color.grey[800]};
  font-size: h4;
  font-weight: 700;
  display: inline;
`

/*
{withdrawable? (
              <StatusValue value={getBalanceNumber(new BigNumber(unlock.amount),pool.tokenDecimal)}  suffix={''}/>
            ):(
              <StatusValue value={getBalanceNumber(new BigNumber(0),pool.tokenDecimal)}  suffix={''}/>
            )}

*/