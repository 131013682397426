import { createContext } from 'react'
import { Notice, NoticeMap } from './types'
interface NoticeContext {
  notices: NoticeMap
  onAddNotice: (tx: Notice) => void
  onRemoveNotice: (txHash: string) => void
}

export default createContext<NoticeContext>({
  notices: {},
  onAddNotice: (tx: Notice) => {},
  onRemoveNotice: (txHash: string) => {}
})
