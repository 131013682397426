import React, { useCallback, useContext, useEffect } from 'react'
import { X } from 'react-feather'
import { useSpring } from 'react-spring'
import styled, { ThemeContext } from 'styled-components'
import { animated } from 'react-spring'
import { Notice } from '../../contexts/Notices/types'
import { useNoticeRemover } from '../../hooks/useNotices'
//import ListUpdatePopup from './ListUpdatePopup'
import TransactionPopup from './TransactionPopup'

export const StyledClose = styled(X)`
  position: absolute;
  right: 10px;
  top: 10px;

  :hover {
    cursor: pointer;
  }
`
export const Popup = styled.div`
  display: inline-block;
  width: 100%;
  padding: 1em;
  background-color: ${({ theme }) => theme.color.white};
  position: relative;
  border-radius: 10px;
  padding: 20px;
  padding-right: 35px;
  overflow: hidden;
  min-width: 200px;
  width: 250px;
`
const Fader = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.color.primary.main};
`

const AnimatedFader = animated(Fader)

export default function PopupItem({
  content
}: {
  content: Notice
}) {
  const {onRemoveNotice} = useNoticeRemover()
  const removeThisPopup = useCallback(() => onRemoveNotice(content.hash), [content, onRemoveNotice])
  useEffect(() => {
    if (content.removeAfterMs === null) return undefined

    const timeout = setTimeout(() => {
      removeThisPopup()
    }, content.removeAfterMs)

    return () => {
      clearTimeout(timeout)
    }
  }, [content, removeThisPopup])

  const theme = useContext(ThemeContext)

  let popupContent
  popupContent = <TransactionPopup hash={content.hash} success={content.success} summary={content.description} />

  const faderStyle = useSpring({
    from: { width: '100%' },
    to: { width: '0%' },
    config: { duration: content.removeAfterMs ?? undefined }
  })

  return (
    <Popup>
      <StyledClose color={theme.color.main} onClick={removeThisPopup} />
      {popupContent}
      {content.removeAfterMs !== null ? <AnimatedFader style={faderStyle} /> : null}
    </Popup>
  )
}
/*
if ('txn' in content) {
    const {
      txn: { hash, success, summary }
    } = content
    popupContent = <TransactionPopup hash={hash} success={success} summary={summary} />
  } else if ('listUpdate' in content) {
    const {
      listUpdate: { listUrl, oldList, newList, auto }
    } = content
    popupContent = <ListUpdatePopup popKey={popKey} listUrl={listUrl} oldList={oldList} newList={newList} auto={auto} />
  }
*/