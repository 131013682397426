 /* eslint-disable */
import React, { useCallback, useMemo, useState } from 'react'
import { useWallet } from 'use-wallet'
import BigNumber from 'bignumber.js'
import styled from 'styled-components'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import CardIcon from '../../../components/CardIcon'
import Label from '../../../components/Label'
import Value from '../../../components/Value'
import useEarnings from '../../../hooks/useEarnings'
import useReward from '../../../hooks/useReward'
import LodIcon from '../../../components/LodIcon'
import useInsurance from '../../../hooks/useInsurance'
import Spacer from '../../../components/Spacer'
import TokenInput from '../../../components/TokenInput'
import { getDisplayBalance,getDisplayBalanceFloor } from '../../../utils/formatBalance'
import { getFullDisplayBalance } from '../../../utils/formatBalance'
import Input from '../../../components/Input'
import Select from '../../../components/Select'
import useStatus from '../../../hooks/useStatus'
//import Select, { components } from "react-select";

interface StatusInputProps {
  insurance: any,
  handleSelectItem: any,
  handleAmountChange: any,
  handleSpanChange: any,
  handleSelectMax: any,
  capacity: any,
  amount: number,
  span: number,
  amountError: boolean,
  spanError: boolean
  value: any
}

const StatusInput: React.FC<StatusInputProps> = ({ insurance, handleSelectItem,handleAmountChange, handleSpanChange, handleSelectMax, capacity, amount, span,amountError,spanError,value}) => {
  const [val, setVal] = useState('')
  
  /*
  const handleAmountChange = (e:any) => {
    setAmount(e.target.value)
  }
  const fullBalance = useMemo(() => {
    return getFullDisplayBalance(new BigNumber(max),18)
  }, [max])


  const handleSpanChange = (e:any) => {
    setSpan(e.target.value)
  }
  */
 /*

  const options = {
    'Pool A': '0xc60b64A65161DA77232142B056728Bb9dCCdD4dF',
    'Pool B': '0xc60b64A65161DA77232142B056728Bb9dCCdD4dd',
  }
  */

  return (
    <Card>
      <CardContent>
        <StyledCardContentInner>
        <StyledTokenInput>
          <StyledTexts>
            <StyledText>Cover Pool</StyledText>
          </StyledTexts>
            <Select
              onChange={handleSelectItem}
              value={value}  
              options={insurance.IDs}
            />
            <StyledNoteText>Select the pool you want to be covered for.</StyledNoteText>
          </StyledTokenInput>
          <StyledTokenInput>
          <StyledTexts>
            <StyledText>Cover Amount</StyledText>
            <StyledNote>{`Capacity: ${getDisplayBalanceFloor(capacity, insurance.tokenDecimal)} ${insurance.tokenSymbol}`}</StyledNote>
          </StyledTexts>
            <Input
              endAdornment={(
                <StyledTokenAdornmentWrapper>
                  <StyledTokenSymbol>{insurance.tokenSymbol}</StyledTokenSymbol>
                  <StyledSpacer />
                  
                </StyledTokenAdornmentWrapper>
              )}
              onChange={handleAmountChange}
              placeholder="0"
              value={amount.toString()}
              error={amountError}
            />
            <StyledNoteText>Enter the amount you want to be covered for.</StyledNoteText>
          </StyledTokenInput>
          
          <StyledTokenInput>
          <StyledTexts>
            <StyledText>Cover Days</StyledText>
            <StyledNote>{`7 ~ 365 days`}</StyledNote>
            </StyledTexts>
            <Input
              endAdornment={(
                <StyledTokenAdornmentWrapper>
                  <StyledTokenSymbol>days</StyledTokenSymbol>
                  <StyledSpacer />
                  <div>
                    <Button size="sm" text="Max" onClick={()=>{handleSelectMax()}} />
                  </div>
                </StyledTokenAdornmentWrapper>
              )}
              onChange={handleSpanChange}
              placeholder="0"
              value={span.toString()}
              error={spanError}
            />
            <StyledNoteText>Enter the number of days to be covered for.</StyledNoteText>
          </StyledTokenInput>

        </StyledCardContentInner>
      </CardContent>
    </Card>
  )
}


const StyledSpacer = styled.div`
  height: ${(props) => props.theme.spacing[2]}px;
  width: ${(props) => props.theme.spacing[2]}px;
`

const StyledCardContentInner = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`
const StyledTitle = styled.h4`
  color: ${(props) => props.theme.color.grey[600]};
  font-size: 24px;
  font-weight: 700;
  margin: ${(props) => props.theme.spacing[2]}px 0 0;
  padding: 0;
`

const StyledDetails = styled.div`
  margin-top: ${(props) => props.theme.spacing[2]}px;
  text-align: center;
`

const StyledDetail = styled.div`
  color: ${(props) => props.theme.color.grey[500]};
`

const StyledTokenInput = styled.div`
width: 100%;
`

const StyledTokenAdornmentWrapper = styled.div`
  align-items: center;
  display: flex;
`

const StyledText = styled.div`
  align-items: center;
  color: ${props => props.theme.color.grey[900]};
  display: flex;
  font-size: 16px;
  font-weight: 700;
  height: 44px;
  justify-content: flex-start;
`

const StyledNote = styled.div`
  align-items: center;
  color: ${props => props.theme.color.grey[900]};
  display: flex;
  font-size: 16px;
  font-weight: 700;
  height: 44px;
  justify-content: flex-end;
`

const StyledTexts = styled.div`
  display: flex;  
  width:100%;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  height: 44px;
  flex-direction: inline;
  justify-content: space-between;
`

const StyledNoteText = styled.div`
  align-items: center;
  color: ${props => props.theme.color.grey[300]};
  display: flex;
  font-size: 14px;
  font-weight: 700;
  margin-top: 4px;
  justify-content: flex-start;
`


const StyledTokenSymbol = styled.span`
  color: ${props => props.theme.color.grey[600]};
  font-weight: 700;
`

export default StatusInput

/*
 <div style={{ flex: 1 }}>
                <Label text="Your LOD Balance" />
                <Value
                  value={111}
                />
              </div>

                        <StyledCardActions>
            <Button
              disabled={!earnings.toNumber() || pendingTx}
              text={'Get quote'}
              onClick={async () => {
                setPendingTx(true)
                await onReward()
                setPendingTx(false)
              }}
            />
          </StyledCardActions>
*/