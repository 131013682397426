 /* eslint-disable */
import { useCallback, useEffect, useState } from 'react'
import { provider } from 'web3-core'

import BigNumber from 'bignumber.js'
import { useWallet } from 'use-wallet'

import { getAllPoolStatus } from '../lod/utils'
import useLod from './useLod'
import useBlock from './useBlock'

export interface StakedValue {
  utilized: BigNumber
  liquidity: BigNumber
  status: string
  apy: number
  leverage: number
  exchange: number
  lockup: number
  withdrawGrace: number
}

const useStatus = (type: string, pid: number) => {
  const [balance, setBalance] = useState({
    utilized: new BigNumber(0),
    liquidity: new BigNumber(0),
    status: "99",
    leverage: 0,
    apy: 0,
    exchange: 0,
    lockup: 0,
    withdrawGrace: 0
  } as StakedValue)
  const {account}: { account: string } = useWallet()
  const lod = useLod()
  const block = useBlock()

  const fetchBalance = useCallback(async () => {
    const balance = await getAllPoolStatus(lod, pid, type)
    setBalance(balance)
  }, [account,  pid])

  useEffect(() => {
    if (account &&  lod) {
      fetchBalance()
    }
  }, [account, block, setBalance, lod, fetchBalance])

  return balance
}

export default useStatus
