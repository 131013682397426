 /* eslint-disable */
import { useCallback, useEffect, useState } from 'react'
import { provider } from 'web3-core'

import BigNumber from 'bignumber.js'
import { useWallet } from 'use-wallet'

import { getUnlock, getMarketContract } from '../lod/utils'
import { constants } from '../lod/lib/constants'

import useLod from './useLod'
import useBlock from './useBlock'

const useUnlock = (pid: number) => {
  const [balance, setBalance] = useState(Object)
  const {account}: { account: string } = useWallet()
  const lod = useLod()
  const market = getMarketContract(lod,pid)
  const block = useBlock()
  const fetchBalance = useCallback(async () => {
    const data = await getUnlock(market, account)
    const timestamp = data[1]
    const amount = data[0]
    //console.log(data,cc)
    setBalance(data)
  }, [account, market])

  useEffect(() => {
    if (account && market) {
      fetchBalance()
    }
  }, [account, market,block])

  return balance
}

export default useUnlock
