 /* eslint-disable */
import React, { useState } from 'react'
import { Route, Switch, useRouteMatch, Redirect,NavLink } from 'react-router-dom'
import { useWallet } from 'use-wallet'
import styled from 'styled-components'
import chef from '../../assets/img/chef.png'
import Button from '../../components/Button'
import Container from '../../components/Container'
import Page from '../../components/Page'
import PageHeader from '../../components/PageHeader'
import Spacer from '../../components/Spacer'
import Card from '../../components/Card'
import CardTitle from '../../components/CardTitle'
import CardContent from '../../components/CardContent'
import useLod from '../../hooks/useLod'

import useModal from '../../hooks/useModal'
import WalletProviderModal from '../../components/WalletProviderModal'
//import { ProposalEntry, StyledDescription, StyledState, StyledButton, StyledProposalContentInner } from "./components/Proposal";
import SeparatorGrid from "./components/SeparatorWithCSS";
import Box from "./components/BoxWithDisplay";
import Surface from "../../components/Surface";
import Separator from "../../components/Separator";
import Mining from "./components/Mining";

const DAO: React.FC = () => {
  const { path } = useRouteMatch()
  const [page, setTab] = useState('insurances')
  const [pendingTx, setPendingTx] = useState(false)
  const [onPresentWalletProviderModal] = useModal(<WalletProviderModal />)
  const lod = useLod()
  const { account }: { account: any; ethereum: any } = useWallet()
  

  const changeTab = (tab:any) =>{
    setTab(tab)
  }
  return (
    <Page>
      {!!account ? (
        <Container>
          <PageHeader
            subtitle='Govern InsureDAO by the DAO'
            title='DAO'
          />
          <Card>
          <CardContent>
            <Box display="grid" alignItems="center" row>
                <StyledLoadingWrapper>
                This section is under development
                </StyledLoadingWrapper>
            </Box>
          </CardContent>
          </Card>
        </Container>
        ) : (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={onPresentWalletProviderModal}
              text="Connect Wallet"
            />
          </div>
        )}
    </Page>
  )
}

const StyledNav = styled.nav`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: ${(props) => props.theme.spacing[4]}px;

`


const StyledLink = styled(NavLink)`
  color: ${(props) => props.theme.color.primary.main};
  font-weight: 700;
  padding-left: ${(props) => props.theme.spacing[1]}px;
  padding-right: ${(props) => props.theme.spacing[2]}px;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.color.main};
  }
  &.active {
    color: ${(props) => props.theme.color.main};
  }
  @media (max-width: 400px) {
    padding-left: ${(props) => props.theme.spacing[2]}px;
    padding-right: ${(props) => props.theme.spacing[2]}px;
  }
`

const StyledInfo = styled.h3`
  color: ${(props) => props.theme.color.white};
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;

  > b {
    color: ${(props) => props.theme.color.white};
  }
`
export const StyledButtonMain = styled.div`
  font-weight: 600;
  display: grid;
  grid-area: vote;
  margin-left: 10px;
  justify-content: center;
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    align-items: flex-start;
  }
`;

export const StyledDescriptionMain = styled.span`
  font-weight: 600;
  display: grid;
  grid-area: desc;
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    align-items: flex-start;
  }
`;

export const StyledStateMain = styled.span`
  font-weight: 600;
  margin-left: 5px;
  margin-right: 5px;
  display: grid;
  grid-area: state;
  justify-content: center;
  min-width: 67px;
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    align-items: flex-start;
  }
`;

export const StyledButton = styled.div`
  display: grid;
  grid-area: vote;
  margin-left: 10px;
  justify-content: center;
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    align-items: flex-start;
  }
`;

export const StyledDescription = styled.span`
  display: grid;
  grid-area: desc;
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    align-items: flex-start;
  }
`;

export const StyledState = styled.span`
  margin-left: 5px;
  margin-right: 5px;
  display: grid;
  grid-area: state;
  justify-content: center;
  min-width: 67px;
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    align-items: flex-start;
  }
`;

export const StyledProposalContentInner = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 70fr 5px 12fr 5px 18fr;
  grid-template-areas: "desc spacer1 state spacer2 vote";
  grid-template-rows: 100fr;
  @media (max-width: 768px) {
    flex-flow: column nowrap;
  }
`;
const StyledLoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 600;
`
export default DAO;
/*

{proposals && (
                <Surface>
                  {proposals.map((prop, i) => {
                    if (i == 0) {
                      return <ProposalEntry key={prop.hash} prop={prop} onVote={onVote} onRegister={onRegister} />;
                    } else {
                      return [<Separator />, <ProposalEntry key={prop.hash} prop={prop} onVote={onVote} onRegister={onRegister} />];
                    }
                  })}
                </Surface>
              )}
<Separator />
            <CardContent>
              <Box display="grid" alignItems="center" padding={4} row>
                <StyledProposalContentInner>
                  <StyledDescription>desc</StyledDescription>
                  <SeparatorGrid orientation={"vertical"} stretch={true} gridArea={"spacer1"} />
                  <StyledState>state</StyledState>
                  <SeparatorGrid orientation={"vertical"} stretch={true} gridArea={"spacer2"} />
                  <StyledButton>
                    Vote
                  </StyledButton>
                </StyledProposalContentInner>
              </Box>
            </CardContent>

          

 <StyledNav>   
              <StyledLink
                exact
                activeClassName="active"
                to={`${path}/mining`} 
              >
                Mining
              </StyledLink>
            </StyledNav>
            <Switch>
              <Route exact path={path}>
                <Redirect to={`${path}/mining`} />
              </Route>
              <Route path={`${path}/mining`}>
                <Mining />
              </Route>
            </Switch>
*/