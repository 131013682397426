
import { Notice, NoticeMap } from './types'

const ADD_NOTICE = 'ADD_NOTICE'
const REMOVE_NOTICE = 'RECEIVE_NOTICE'
const SET_NOTICES = 'SET_NOTICES'

interface AddNoticeAction {
  type: typeof ADD_NOTICE,
  notice: Notice
}

interface RemoveNoticeAction {
  type: typeof REMOVE_NOTICE,
  txHash: string
}

interface SetNoticeAction {
  type: typeof SET_NOTICES,
  notices: NoticeMap
}

type NoticeActions = AddNoticeAction | RemoveNoticeAction | SetNoticeAction

export interface NoticeState {
  initialized: boolean,
  notices: NoticeMap
}

export const addNotice = (
  notice: Notice
  ): AddNoticeAction => ({
    type: ADD_NOTICE,
    notice
})

export const removeNotice = (txHash: string): RemoveNoticeAction => ({
  type: REMOVE_NOTICE,
  txHash
})

export const setNotice = (notices: NoticeMap): SetNoticeAction => ({
  type: SET_NOTICES,
  notices,
})

export const initialState: NoticeState = {
  initialized: false,
  notices: {}
}

const reducer = (state: NoticeState, action: NoticeActions): NoticeState => {
  switch (action.type) {
    case ADD_NOTICE:
      return {
        ...state,
        notices: {
          ...state.notices,
          [action.notice.hash]: action.notice,
        }
      }
    case REMOVE_NOTICE:
      return {
        ...state,
        notices: {
          ...state.notices,
          [action.txHash]: {
            ...state.notices[action.txHash],
            show: false
          }
        },
      }
    case SET_NOTICES:
      return {
        ...state,
        notices: action.notices,
        initialized: true,
      }
    default:
      return state
  }
}

export default reducer