 /* eslint-disable */
import { useCallback } from 'react'
import BigNumber from 'bignumber.js'
import useLod from './useLod'
import { useWallet } from 'use-wallet'
import {useNoticeAdder} from './useNotices'
import  {useTransactionAdder,useTransactionReceiver}  from './useTransaction'
import { stake, getMarketContract } from '../lod/utils'
import { deposit, getGaugeContract } from '../lod/utils'

const useDeposit = (pid: number, tokenDecimal:number) => {
  const { account } = useWallet()
  const lod = useLod()
  const { onAddTransaction } = useTransactionAdder()
  const { onReceiveTransaction } = useTransactionReceiver()
  const { onAddNotice } = useNoticeAdder()
  const handleDeposit = useCallback(
    async (amount: any) => {
      try {
        const txHash = await deposit(
          getGaugeContract(lod,pid),
          new BigNumber(amount).times(new BigNumber(10).pow(tokenDecimal)).toString(),
          account,
          (tx: any) => {
            onAddTransaction(tx)
          },
          (hash: any, receipt: any) => {
            onReceiveTransaction(hash,receipt)
          },
          (content: any) => {
            onAddNotice(content)
          }
        )
        return txHash
      } catch (e) {
        return false
      }
      
    },
    [account, pid, lod],
  )

  return { onDeposit: handleDeposit }
}

export default useDeposit
