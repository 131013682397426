import BigNumber from 'bignumber.js'

export const getBalanceNumber = (balance: BigNumber, decimals: number) => {
  const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals))
  return displayBalance.toNumber()
}

export const getDisplayBalance = (balance: BigNumber, decimals : number) => {
  const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals))
  if (displayBalance.lt(1)) {
    return displayBalance.toPrecision(3)
  } else {
    return (Math.floor(Number(displayBalance)*100)/100).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
}

export const getFullDisplayBalance = (balance: BigNumber, decimals: number) => {
  return (Math.floor( Number(balance.dividedBy(new BigNumber(10).pow(decimals)))*100)/100).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
}
export const getDisplayBalanceFloor = (balance: BigNumber, decimals: number) => {
  return (Math.floor( Number(balance.dividedBy(new BigNumber(10).pow(decimals)))*100)/100).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})
}