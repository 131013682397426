 /* eslint-disable */
import BigNumber from 'bignumber.js'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import Button from '../../../components/Button'
import Modal, { ModalProps } from '../../../components/Modal'
import ModalActions from '../../../components/ModalActions'
import ModalTitle from '../../../components/ModalTitle'
import TokenInput from '../../../components/TokenInput'
import { getFullDisplayBalance,getBalanceNumber } from '../../../utils/formatBalance'

interface DepositModalProps extends ModalProps {
  max: BigNumber
  decimal: number
  onConfirm: (amount: string) => void
  tokenName?: string
  type?: string
}

const DepositModal: React.FC<DepositModalProps> = ({
  max,
  decimal,
  onConfirm,
  onDismiss,
  tokenName = '',
  type
}) => {
  const [val, setVal] = useState('')
  const [pendingTx, setPendingTx] = useState(false)

  const fullBalance = useMemo(() => {
    return getBalanceNumber(max,decimal)
  }, [max])

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setVal(e.currentTarget.value)
    },
    [setVal],
  )

  const handleSelectMax = useCallback(() => {
    setVal(fullBalance.toString())
  }, [fullBalance, setVal])
  
  return (
    <Modal>
      <ModalTitle text={`Deposit ${tokenName}`} />
      <TokenInput
        value={val}
        onSelectMax={handleSelectMax}
        onChange={handleChange}
        max={fullBalance}
        symbol={tokenName}
        available={true}
      />
      {type === "CDS"?
        ( 
          <>
            <StyledText>Fee rate of 1% will be deducted upon deposit</StyledText>
            <StyledDetail>
            The DAO module will receive this fee.  
            The DAO module will mint INSURE token in the case CDS cannot afford an Index's loss.
            </StyledDetail>
          </>
        ):(
          <>
            {type === "Index"?(
              <>
              <StyledText>Fee rate of 3% will be deducted upon deposit</StyledText>
              <StyledDetail>
              The fee here will be split between the CDS's premium and DAO fees. The CDS is "an insurance" for index pools. 
              The DAO module will mint INSURE token in the case CDS cannot afford an Index's loss.
              </StyledDetail>
              </>
            ):(
              <></> 
            )}
          </>
        )}
      <ModalActions>
        <Button text="Cancel" variant="secondary" onClick={onDismiss} />
        <Button
          disabled={pendingTx　|| Number(val) ===0 }
          text={pendingTx ? 'Pending Confirmation..' : 'Confirm'}
          onClick={async () => {
            setPendingTx(true)
            await onConfirm(val)
            
            setPendingTx(false)
            onDismiss()
          }}
        />
      </ModalActions>
    </Modal>
  )
}

export default DepositModal

const StyledText = styled.div`
  align-items: center;
  color: ${props => props.theme.color.red[500]};
  display: flex;
  font-size: 16px;
  font-weight: 700;
  height: 44px;
  justify-content: center;
`
const StyledDetail = styled.div`
  align-items: center;
  color: ${props => props.theme.color.grey[800]};
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 44px;
  justify-content: center;
`