 /* eslint-disable */
import React from 'react'
import { Route, Switch, useRouteMatch,Redirect } from 'react-router-dom'
import { useWallet } from 'use-wallet'

import chef from '../../assets/img/chef.png'

import Button from '../../components/Button'
import Page from '../../components/Page'
import PageHeader from '../../components/PageHeader'
import WalletProviderModal from '../../components/WalletProviderModal'

import useModal from '../../hooks/useModal'

import Insurance from '../Insurance'

import InsuranceCards from './components/InsuranceCards'

const Insurances: React.FC = () => {
  const { path } = useRouteMatch()
  const { account } = useWallet()
  const [onPresentWalletProviderModal] = useModal(<WalletProviderModal />)
  return (
    <Switch>
      <Page>
        {!!account ? (
          <>
            <Route exact path={path}>
              <PageHeader
                subtitle="Select insurances to get covered from accidents"
                title="Insurance"
              />
              <InsuranceCards />
            </Route>
            <Route path="/" >
              <Redirect to="/insurances" />
            </Route>
            <Route path={`${path}/:insuranceId`}>
              <Insurance />
            </Route>
            
          </>
        ) : (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={onPresentWalletProviderModal}
              text="Connect Wallet"
            />
          </div>
        )}
      </Page>
    </Switch>
  )
}

export default Insurances
