 /* eslint-disable */
import { useCallback, useEffect, useState } from 'react'
import { Contract } from 'web3-eth-contract'
import BigNumber from 'bignumber.js'
import { useWallet } from 'use-wallet'
import { provider } from 'web3-core'
import useLod from '../hooks/useLod'
import { getCost,getMarketContract } from '../lod/utils'
import useBlock from './useBlock'

const useQuote = (pid: number, span: number, amount: number, decimal: number) => {
  const [cost, setCost] = useState(new BigNumber(0))
  const {
    account,
    ethereum,
  }: { account: string; ethereum: provider } = useWallet()
  const lod = useLod()
  const marketContract = getMarketContract(lod,pid)
  const block = useBlock()
  const fetchCost = useCallback(async () => {
    const cost = await getCost(marketContract, new BigNumber(span).times(86400).toString(), new BigNumber(amount).times(new BigNumber(10).pow(decimal)).toString())
    setCost(new BigNumber(cost))
  }, [account, marketContract, pid, span, amount])

  useEffect(() => {
    if (account && ethereum &&lod) {
      fetchCost()
    }
  }, [account, marketContract, span, amount, lod ,fetchCost])

  return cost
}

export default useQuote
