import { createContext } from 'react'
import { Transaction, TransactionsMap } from './types'
import { TransactionReceipt } from 'web3-core'
interface TransactionsContext {
  transactions: TransactionsMap
  onAddTransaction: (tx: Transaction) => void
  onReceiveTransaction: (txHash: string, receipt:TransactionReceipt) => void
}

export default createContext<TransactionsContext>({
  transactions: {},
  onAddTransaction: (tx: Transaction) => {},
  onReceiveTransaction: (txHash: string, receipt:TransactionReceipt) => {}
})
