/* eslint-disable */
import React, { useEffect, useCallback, useState,useReducer } from 'react'
import Web3 from 'web3'
import Context from './context'
import { provider } from 'web3-core'
import { useWallet } from 'use-wallet'
import reducer, {
initialState,
setNotice,
addNotice,
removeNotice
} from './reducer'
import { Notice, NoticeMap } from './types'


const NoticeProvider: React.FC = ({ children }) => {
  const [{ initialized, notices }, dispatch] = useReducer(reducer, initialState)
  
  const handleAddNotice = useCallback((notice: Notice) => {
    dispatch(addNotice(notice))
  }, [dispatch])

  const handleRemoveNotice = useCallback((txHash: string ) => {
    dispatch(removeNotice(txHash))
  }, [dispatch])

  const fetchTransactions = useCallback(async () => {
    try {
      const txsRaw = localStorage.getItem('transactions')
      const txs = JSON.parse(txsRaw) as NoticeMap || {}
      dispatch(setNotice(txs))
    } catch (e) {
      console.log(e)
    }
  }, [dispatch])

  useEffect(() => {
    if (initialized) {
      localStorage.setItem('transactions', JSON.stringify(notices))
    }
  }, [initialized, notices])

  useEffect(() => {
    fetchTransactions()
  }, [fetchTransactions])

  
  return (
    <Context.Provider value={{
      notices,
      onAddNotice: handleAddNotice,
      onRemoveNotice: handleRemoveNotice,
    }}>
      {children}
    </Context.Provider>
  )
}



export default NoticeProvider
 