/* eslint-disable */
import BigNumber from 'bignumber.js'
import ERC20Abi from './abi/erc20.json'
import PoolTemplateAbi from './abi/PoolTemplate.json'
import IndexTemplateAbi from './abi/IndexTemplate.json'
import ParametersAbi from './abi/Parameters.json'
import LiquidityGaugeAbi from './abi/LiquidityGauge.json'
import UniswapAbi from './abi/IUniswapV2Router01.json'
import InsureAbi from './abi/InsureToken.json'
import GaugeAbi from './abi/GaugeController.json'
import MinterAbi from './abi/Minter.json'
import ERC20MockAbi from './abi/ERC20Mock.json'

import {
  contractAddresses,
  SUBTRACT_GAS_LIMIT,
  supportedPools,
  constants,
  ZERO_ADDRESS,
} from './constants.js'
import * as Types from './types.js'
import { add } from 'numeral'

export class Contracts {
  constructor(provider, networkId, web3, options) {
    this.web3 = web3
    this.defaultConfirmations = options.defaultConfirmations
    this.autoGasMultiplier = options.autoGasMultiplier || 1.5
    this.confirmationType =
      options.confirmationType || Types.ConfirmationType.Confirmed
    this.defaultGas = options.defaultGas
    this.defaultGasPrice = options.defaultGasPrice
    this.insuretoken = new this.web3.eth.Contract(
      InsureAbi.abi,
      contractAddresses.Insure[networkId],
    )
    this.gc = new this.web3.eth.Contract(
      GaugeAbi.abi,
      contractAddresses.GaugeController[networkId],
    )
    this.insureAddress = contractAddresses.Insure[networkId]
    this.gcAddress = contractAddresses.GaugeController[networkId]
    this.minterContract = new this.web3.eth.Contract(
      MinterAbi.abi,
      contractAddresses.Minter[networkId],
    )
    this.pools = supportedPools.map((pool) =>
      Object.assign(pool, {
        marketAddress: pool.marketAddresses[networkId],
        tokenAddress: pool.tokenAddresses[networkId],
        vaultAddress: pool.vault[networkId],
        parameterAddress: pool.parameter[networkId],
        lockup: constants.lockup[networkId],
        gaugeAddress: pool.gaugeAddresses[networkId],
        gaugeContract: new this.web3.eth.Contract(LiquidityGaugeAbi.abi),
        pools: pool.type === 'Index' ? pool.poolList[networkId] : 'none',
        indexes: pool.type === 'CDS' ? pool.indexList[networkId] : 'none',
        IDs: pool.type === 'Individual' ? pool.IDList[networkId] : 'none',
        withdrawGrace: constants.withdrawGrace[networkId],
        marketContract:
          pool.type === 'Individual'
            ? new this.web3.eth.Contract(PoolTemplateAbi.abi)
            : new this.web3.eth.Contract(IndexTemplateAbi.abi),
        tokenContract: new this.web3.eth.Contract(ERC20MockAbi.abi),
        parameterContract: new this.web3.eth.Contract(ParametersAbi.abi),
      }),
    )

    this.setEachProvider(provider, networkId)
    this.setDefaultAccount(this.web3.eth.defaultAccount)
  }

  setEachProvider(provider, networkId) {
    const set = (contract, address) => {
      contract.setProvider(provider)
      if (address) contract.options.address = address
      else console.error('Contract address not found in network', networkId)
    }
    this.pools.forEach(
      ({
        marketContract,
        marketAddress,
        tokenContract,
        tokenAddress,
        parameterContract,
        parameterAddress,
        gaugeAddress,
        gaugeContract,
      }) => {
        set(marketContract, marketAddress)
        set(tokenContract, tokenAddress)
        set(parameterContract, parameterAddress)
        set(gaugeContract, gaugeAddress)
      },
    )
  }

  setDefaultAccount(account) {
    //this.treasury.options.from = account
  }

  async callContractFunction(method, options) {
    const { confirmations, confirmationType, autoGasMultiplier, ...txOptions } =
      options

    if (!this.blockGasLimit) {
      await this.setGasLimit()
    }

    if (!txOptions.gasPrice && this.defaultGasPrice) {
      txOptions.gasPrice = this.defaultGasPrice
    }

    if (confirmationType === Types.ConfirmationType.Simulate || !options.gas) {
      let gasEstimate
      if (
        this.defaultGas &&
        confirmationType !== Types.ConfirmationType.Simulate
      ) {
        txOptions.gas = this.defaultGas
      } else {
        try {
          console.log('estimating gas')
          gasEstimate = await method.estimateGas(txOptions)
        } catch (error) {
          const data = method.encodeABI()
          const { from, value } = options
          const to = method._parent._address
          error.transactionData = { from, value, data, to }
          throw error
        }

        const multiplier = autoGasMultiplier || this.autoGasMultiplier
        const totalGas = Math.floor(gasEstimate * multiplier)
        txOptions.gas =
          totalGas < this.blockGasLimit ? totalGas : this.blockGasLimit
      }

      if (confirmationType === Types.ConfirmationType.Simulate) {
        let g = txOptions.gas
        return { gasEstimate, g }
      }
    }

    if (txOptions.value) {
      txOptions.value = new BigNumber(txOptions.value).toFixed(0)
    } else {
      txOptions.value = '0'
    }

    const promi = method.send(txOptions)

    const OUTCOMES = {
      INITIAL: 0,
      RESOLVED: 1,
      REJECTED: 2,
    }

    let hashOutcome = OUTCOMES.INITIAL
    let confirmationOutcome = OUTCOMES.INITIAL

    const t =
      confirmationType !== undefined ? confirmationType : this.confirmationType

    if (!Object.values(Types.ConfirmationType).includes(t)) {
      throw new Error(`Invalid confirmation type: ${t}`)
    }

    let hashPromise
    let confirmationPromise

    if (
      t === Types.ConfirmationType.Hash ||
      t === Types.ConfirmationType.Both
    ) {
      hashPromise = new Promise((resolve, reject) => {
        promi.on('error', (error) => {
          if (hashOutcome === OUTCOMES.INITIAL) {
            hashOutcome = OUTCOMES.REJECTED
            reject(error)
            const anyPromi = promi
            anyPromi.off()
          }
        })

        promi.on('transactionHash', (txHash) => {
          if (hashOutcome === OUTCOMES.INITIAL) {
            hashOutcome = OUTCOMES.RESOLVED
            resolve(txHash)
            if (t !== Types.ConfirmationType.Both) {
              const anyPromi = promi
              anyPromi.off()
            }
          }
        })
      })
    }

    if (
      t === Types.ConfirmationType.Confirmed ||
      t === Types.ConfirmationType.Both
    ) {
      confirmationPromise = new Promise((resolve, reject) => {
        promi.on('error', (error) => {
          if (
            (t === Types.ConfirmationType.Confirmed ||
              hashOutcome === OUTCOMES.RESOLVED) &&
            confirmationOutcome === OUTCOMES.INITIAL
          ) {
            confirmationOutcome = OUTCOMES.REJECTED
            reject(error)
            const anyPromi = promi
            anyPromi.off()
          }
        })

        const desiredConf = confirmations || this.defaultConfirmations
        if (desiredConf) {
          promi.on('confirmation', (confNumber, receipt) => {
            if (confNumber >= desiredConf) {
              if (confirmationOutcome === OUTCOMES.INITIAL) {
                confirmationOutcome = OUTCOMES.RESOLVED
                resolve(receipt)
                const anyPromi = promi
                anyPromi.off()
              }
            }
          })
        } else {
          promi.on('receipt', (receipt) => {
            confirmationOutcome = OUTCOMES.RESOLVED
            resolve(receipt)
            const anyPromi = promi
            anyPromi.off()
          })
        }
      })
    }

    if (t === Types.ConfirmationType.Hash) {
      const transactionHash = await hashPromise
      if (this.notifier) {
        this.notifier.hash(transactionHash)
      }
      return { transactionHash }
    }

    if (t === Types.ConfirmationType.Confirmed) {
      return confirmationPromise
    }

    const transactionHash = await hashPromise
    if (this.notifier) {
      this.notifier.hash(transactionHash)
    }
    return {
      transactionHash,
      confirmation: confirmationPromise,
    }
  }

  async callConstantContractFunction(method, options) {
    const m2 = method
    const { blockNumber, ...txOptions } = options
    return m2.call(txOptions, blockNumber)
  }

  async setGasLimit() {
    const block = await this.web3.eth.getBlock('latest')
    this.blockGasLimit = block.gasLimit - SUBTRACT_GAS_LIMIT
  }
}
