 /* eslint-disable */
import React, { useEffect, useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useWallet } from 'use-wallet'
import { provider } from 'web3-core'
import PageHeader from '../../components/PageHeader'
import Spacer from '../../components/Spacer'
import useInsurance from '../../hooks/useInsurance'
import useQuote from '../../hooks/useQuote'
import BigNumber from 'bignumber.js'

//import { getMasterChefContract } from '../../lod/utils'
import { getContract } from '../../utils/erc20'
import StatusInput from './components/StatusInput'
import QuoteDisplay from './components/QuoteDisplay'
import {  getMarketContract } from '../../lod/utils'
import useStatus from '../../hooks/useStatus'

const Insurance: React.FC = () => {
  const [span, setSpan] = useState(0)
  const [item, setItem] = useState("0xaa")
  const [amount, setAmount] = useState(0)
  const [amountError, setAmountError] = useState(false)
  const [spanError, setSpanError] = useState(false)
  const { insuranceId } = useParams()
  const { ethereum } = useWallet()

  const insurance  = useInsurance(insuranceId) || {
    pid: 0,
    description: '',
    tokenName: '',
    marketAddress: '',
    tokenAddress: '',
    tokenSymbol: '',
    tokenDecimal: 18,
    name: '',
    type: '',
    icon: '',
    base: 0,
    multiple: 0,
    fee:0,
    url:'',
    payout:'',
    IDs: [
      { value: "0", label: "Loading..." }
    ]
  }
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const tokenContract = useMemo(() => {
    return getContract(ethereum as provider, insurance.tokenAddress)
  }, [ethereum, insurance.tokenAddress])

  const re = /^[0-9\b]+$/;
  const max = 365
  const min = 7
  const values = useStatus(insurance.type, insurance.pid)
  
  const utilized = new BigNumber(values.utilized)
  const tvl = new BigNumber(values.liquidity)
  const capacity = tvl === new BigNumber(0)  ? new BigNumber(0) : tvl.minus(utilized)
  
  const amountToQuote = () => {
    if(capacity.div(10 **insurance.tokenDecimal).toNumber() <= amount){
      return capacity.div(10 **insurance.tokenDecimal).toNumber()
    }else{
      return amount
    }
  }
  const spanToQuote = () => {
    if(max <= span){
      return max
    }else{
      return span
    }
  }

  const quote = useQuote(insurance.pid,spanToQuote(),amountToQuote(),insurance.tokenDecimal)
  

  useEffect(() => {
    setItem(insurance.IDs[0].value)
  }, [insurance])

  

  const handleSelectMax = () => {
    setSpan(max)
  }
  const handleAmountChange = useCallback(
    (e: any) => {
      if (e.target.value === '' || re.test(e.target.value)) {
        setAmount(Number(e.target.value))
        if(capacity.div(10 **insurance.tokenDecimal).toNumber() < Number(e.target.value)){
          setAmountError(true)
        }else{
          setAmountError(false)
        }
      }
    },
    [setAmount,capacity],
  )
  const handleSpanChange = useCallback(
    (e: any) => {
      if (e.target.value === '' || re.test(e.target.value)) {
        setSpan(Number(e.target.value))
        if(max < Number(e.target.value) ||min > Number(e.target.value)){
          setSpanError(true)
        }else{
          setSpanError(false)
        }
      }
    },
    [setSpan],
  )
  const handleSelectItem = useCallback(
    (e: any) => {
        console.log(e.target.value)
        setItem(e.target.value)
        
    },
    [setItem],
  )


  return (
    <>
      <PageHeader
        icon={insurance.icon}
        subtitle={insurance.description}
        title={insurance.name}
      />
      <StyledInsurance>
        <StyledCardsWrapper>
          <StyledCardWrapper>
            <StatusInput 
              insurance={insurance}
              handleSelectItem={handleSelectItem} 
              handleAmountChange={handleAmountChange} 
              handleSpanChange={handleSpanChange}
              handleSelectMax={handleSelectMax}
              capacity={capacity}
              amount={amount}
              span={span}
              amountError={amountError}
              spanError={spanError}
              value={item}
            />
          </StyledCardWrapper>
          <Spacer />
          <StyledCardWrapper>
            <QuoteDisplay
              insurance={insurance}
              tokenContract={tokenContract}
              amount={amountToQuote()}
              span={spanToQuote()}
              item={item}
              quote={quote}
              min={min}
            />
          </StyledCardWrapper>
        </StyledCardsWrapper>
        <Spacer size="lg" />
        <StyledInfo>
          ⭐️ Your purchased insurances will be listed on Dashboard.
        </StyledInfo>
        <Spacer size="lg" />
      </StyledInsurance>
    </>
  )
}

const StyledInsurance = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const StyledCardsWrapper = styled.div`
  display: flex;
  width: 768px;
  @media (max-width: 768px) {
    width: 100%;
    flex-flow: column nowrap;
    align-items: center;
  }
`

const StyledCardWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 80%;
  }
`

const StyledInfo = styled.h3`
  color: ${(props) => props.theme.color.white};
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;
`

export default Insurance
