//address here should be small capital letters

import BigNumber from 'bignumber.js'

export const SUBTRACT_GAS_LIMIT = 100000

const ONE_MINUTE_IN_SECONDS = new BigNumber(60)
const ONE_HOUR_IN_SECONDS = ONE_MINUTE_IN_SECONDS.times(60)
const ONE_DAY_IN_SECONDS = ONE_HOUR_IN_SECONDS.times(24)
const ONE_YEAR_IN_SECONDS = ONE_DAY_IN_SECONDS.times(365)

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const INTEGERS = {
  ONE_MINUTE_IN_SECONDS,
  ONE_HOUR_IN_SECONDS,
  ONE_DAY_IN_SECONDS,
  ONE_YEAR_IN_SECONDS,
  ZERO: new BigNumber(0),
  ONE: new BigNumber(1),
  ONES_31: new BigNumber('4294967295'), // 2**32-1
  ONES_127: new BigNumber('340282366920938463463374607431768211455'), // 2**128-1
  ONES_255: new BigNumber(
    '115792089237316195423570985008687907853269984665640564039457584007913129639935',
  ), // 2**256-1
  INTEREST_RATE_BASE: new BigNumber('1e18'),
}

export const contractAddresses = {
  Insure: {
    4: '0xFcFFb6190CBdA589E73DB0e8438A3A72bAf27d82',
  },
  Uniswap: {
    4: '0xFc6f37e76BC7A1EAB8E5317b56Ad65b65ED7ce1E',
  },
  GaugeController: {
    4: '0xaaCCB105554b6c60355500FDabbEe705aaF60d0E',
  },
  Minter: {
    4: '0xcDc1fA42c43b45571944507DD1B50010b7CD060D',
  },
  //This list is used for general transactions
}

export const constants = {
  lockup: {
    4: 300,
  },
  withdrawGrace: {
    4: 86400000,
  },
  //This list is used for general transactions
}

export const supportedPools = [
  {
    pid: 1,
    id: 'Index',
    payout: 'https://insuredao.gitbook.io/insuredao/reporting-1/reporting',
    url: 'https://insuredao.gitbook.io/insuredao/protocol-module/insurance-pools/indexes',
    tokenAddresses: {
      1: '0x00',
      4: '0x1A96673Fd6EBEED815D07c7Aba998F75fdD432b6',
    },
    marketAddresses: {
      1: '0x00',
      4: '0x7D976f20f27C042768a1659AE5a307e5e98EfcbD',
    },
    gaugeAddresses: {
      1: '0x00',
      4: '0x79d0A9ddF16E1C5D5dbA9e7bEc59E0fe049cb153',
    },
    vault: {
      1: '0x00',
      4: '0x52200e7D77CB8322CF06702E29FD04CBD2c15E0B',
    },
    parameter: {
      1: '0x00',
      4: '0xBA95A275AEF498D64cBF0de40aeEd49ADFd334e1',
    },
    poolList: {
      1: [
        {
          address: '0x6d9A44C3e2aBA622795eE63D00E110d970D2D394',
          credit: 1000,
        },
        {
          address: '0xcCEE07453563e71B9c6fa8693667ff388Ce12D86',
          credit: 1000,
        },
      ],
      4: [
        {
          address: '0x0A7B7A8dc9786777D813A5da5Aa0F62ed3165a25',
          credit: 1000,
        },
        {
          address: '0x7Ef695C4Eb53e008616Fe332a30b752EB1824591',
          credit: 1000,
        },
        {
          address: '0x69F10f16d088EBD74Ab96762B5cbA3D87818AC09',
          credit: 1000,
        },
      ],
    },
    name: 'Index',
    description: 'InsureDAO Official Index',
    icon: 'index',
    tokenSymbol: 'USDC',
    type: 'Index',
    totalCredit: 2000,
    leverage: 20000,
    tokenDecimal: 6,
    base: 0.03,
    multiple: 0.5,
    fee: 0.01,
  },
  {
    pid: 2,
    id: 'Hokusai',
    url: 'https://en.wikipedia.org/wiki/Hokusai',
    payout: 'https://insuredao.gitbook.io/insuredao/reporting-1/reporting',
    tokenAddresses: {
      1: '0x00',
      4: '0x1A96673Fd6EBEED815D07c7Aba998F75fdD432b6',
    },
    marketAddresses: {
      1: '0x00',
      4: '0x0A7B7A8dc9786777D813A5da5Aa0F62ed3165a25',
    },
    gaugeAddresses: {
      1: '0x00',
      4: '0x819668109709acb70E0D3c735D92cea1E8F4D4F2',
    },
    vault: {
      1: '0x00',
      4: '0x52200e7D77CB8322CF06702E29FD04CBD2c15E0B',
    },
    parameter: {
      1: '0x00',
      4: '0xBA95A275AEF498D64cBF0de40aeEd49ADFd334e1',
    },
    IDList: {
      1: [
        {
          value: '0xF8405C02994f5f2e8f257fC5A77cF25B98C0BB92',
          label: 'sample pool',
        },
        {
          value: '0xF8405C02994f5f2e8f257fC5A77cF25B98C0BB91',
          label: 'sample2 pool',
        },
        {
          value:
            '0x4e69636b00000000000000000000000000000000000000000000000000000000',
          label: 'test pool',
        },
      ],
      4: [
        {
          value:
            '0xf8405c02994f5f2e8f257fc5a77cf25b98c0bb91000000000000000000000000',
          label: 'DAI pool',
        },
        {
          value:
            '0xf8405c02994f5f2e8f257fc5a77cf25b98c0bb92000000000000000000000000',
          label: 'USDC pool',
        },
      ],
    },
    name: 'Hokusai finance',
    description: 'Hacking cover for Hokusai',
    icon: 'hokusai',
    tokenSymbol: 'USDC',
    type: 'Individual',
    tokenDecimal: 6,
    base: 0.03,
    multiple: 0.5,
    fee: 0.01,
  },
  {
    pid: 3,
    id: 'Shogun',
    url: 'https://en.wikipedia.org/wiki/Shogun',
    payout: 'https://insuredao.gitbook.io/insuredao/reporting-1/reporting',
    tokenAddresses: {
      1: '0x00',
      4: '0x1A96673Fd6EBEED815D07c7Aba998F75fdD432b6',
    },
    marketAddresses: {
      1: '0x00',
      4: '0x7Ef695C4Eb53e008616Fe332a30b752EB1824591',
    },
    gaugeAddresses: {
      1: '0x00',
      4: '0x4798786C63C26496bB462cBe6D2447FC9fD056E6',
    },
    vault: {
      1: '0x00',
      4: '0x52200e7D77CB8322CF06702E29FD04CBD2c15E0B',
    },
    parameter: {
      1: '0x00',
      4: '0xBA95A275AEF498D64cBF0de40aeEd49ADFd334e1',
    },
    IDList: {
      1: [
        {
          value: '0xF8405C02994f5f2e8f257fC5A77cF25B98C0BB92',
          label: 'DAI pool',
        },
        {
          value: '0xF8405C02994f5f2e8f257fC5A77cF25B98C0BB91',
          label: 'USDC pool',
        },
      ],
      4: [
        {
          value:
            '0xf8405c02994f5f2e8f257fc5a77cf25b98c0bb91000000000000000000000000',
          label: 'DAI pool',
        },
        {
          value:
            '0xf8405c02994f5f2e8f257fc5a77cf25b98c0bb92000000000000000000000000',
          label: 'USDC pool',
        },
      ],
    },
    name: 'Shogun finance',
    description: 'Hacking cover for Shogun',
    icon: 'shogun',
    tokenSymbol: 'USDC',
    type: 'Individual',
    tokenDecimal: 6,
    base: 0.03,
    multiple: 0.5,
    fee: 0.01,
  },
  {
    pid: 4,
    id: 'Ronin',
    url: 'https://en.wikipedia.org/wiki/R%C5%8Dnin',
    payout: 'https://insuredao.gitbook.io/insuredao/reporting-1/reporting',
    tokenAddresses: {
      1: '0x00',
      4: '0x1A96673Fd6EBEED815D07c7Aba998F75fdD432b6',
    },
    marketAddresses: {
      1: '0x00',
      4: '0x69F10f16d088EBD74Ab96762B5cbA3D87818AC09',
    },
    gaugeAddresses: {
      1: '0x00',
      4: '0x86ef2D96b236FEb1cC62C90EEfDB4100dc8fcc24',
    },
    vault: {
      1: '0x00',
      4: '0x52200e7D77CB8322CF06702E29FD04CBD2c15E0B',
    },
    parameter: {
      1: '0x00',
      4: '0xBA95A275AEF498D64cBF0de40aeEd49ADFd334e1',
    },
    IDList: {
      1: [
        {
          value: '0xF8405C02994f5f2e8f257fC5A77cF25B98C0BB92',
          label: 'DAI pool',
        },
        {
          value: '0xF8405C02994f5f2e8f257fC5A77cF25B98C0BB91',
          label: 'USDC pool',
        },
      ],
      4: [
        {
          value:
            '0xf8405c02994f5f2e8f257fc5a77cf25b98c0bb91000000000000000000000000',
          label: 'DAI pool',
        },
        {
          value:
            '0xf8405c02994f5f2e8f257fc5a77cf25b98c0bb92000000000000000000000000',
          label: 'USDC pool',
        },
      ],
    },
    name: 'Ronin finance',
    description: 'Hacking cover for Ronin finance',
    icon: 'ronin',
    tokenSymbol: 'USDC',
    type: 'Individual',
    tokenDecimal: 6,
    base: 0.03,
    multiple: 0.5,
    fee: 0.01,
  },

  {
    pid: 5,
    id: 'cds',
    payout: 'https://insuredao.fi',
    url: 'https://insuredao.gitbook.io/insuredao/protocol-module/insurance-pools/cds',
    tokenAddresses: {
      1: '0x00',
      4: '0x1A96673Fd6EBEED815D07c7Aba998F75fdD432b6',
    },
    marketAddresses: {
      1: '0x00',
      4: '0xE4f0498eE8415b0BE861f1Ad22e3f6c42485acA0',
    },
    gaugeAddresses: {
      1: '0x00',
      4: '0xF5B4a1A13558C0098904Da6B4a98dF07654f8231',
    },
    vault: {
      1: '0x00',
      4: '0x52200e7D77CB8322CF06702E29FD04CBD2c15E0B',
    },
    parameter: {
      1: '0x00',
      4: '0xBA95A275AEF498D64cBF0de40aeEd49ADFd334e1',
    },
    indexList: {
      1: [
        '0x6d9A44C3e2aBA622795eE63D00E110d970D2D394',
        '0x6d9A44C3e2aBA622795eE63D00E110d970D2D394',
      ],
      4: ['0x7D976f20f27C042768a1659AE5a307e5e98EfcbD'],
    },
    name: 'CDS',
    description: 'Credit Default Swap for Index',
    icon: 'cds',
    tokenSymbol: 'USDC',
    type: 'CDS',
    tokenDecimal: 6,
    base: 0.03,
    multiple: 0.5,
    fee: 0.01,
  },
]

/**Rinkeby need to be changed */
