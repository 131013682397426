 /* eslint-disable */
import BigNumber from 'bignumber.js'
import React, { useEffect, useState } from 'react'
import Countdown, { CountdownRenderProps } from 'react-countdown'
import styled, { keyframes } from 'styled-components'
import { symbolName } from 'typescript'
import { useWallet } from 'use-wallet'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import Loader from '../../../components/Loader'
import Spacer from '../../../components/Spacer'
import { Insurance } from '../../../contexts/Insurances'
import useAllInsuranceStatus from '../../../hooks/useAllInsuranceStatus'
import useInsurances from '../../../hooks/useInsurances'
import useLod from '../../../hooks/useLod'
//import { getEarned, getMasterChefContract } from '../../../lod/utils'
import { bnToDec } from '../../../utils'

interface InsuranceWithStakedValue extends Insurance {
  liquidity: BigNumber,
  premium: BigNumber,
  utilized: BigNumber,
  status: string
  apy: BigNumber
}

const InsuranceCards: React.FC = () => {
  const [insurances] = useInsurances()
  const { account } = useWallet()
  //const stakedValue = useAllStakedValue()
  const values = useAllInsuranceStatus()
  
  
  /*
  const lodIndex = insurances.findIndex(({ tokenSymbol }) => tokenSymbol === 'LOD')
  const lodPrice =
    lodIndex >= 0 && stakedValue[lodIndex]
      ? stakedValue[lodIndex].tokenPriceInWeth
      : new BigNumber(0)
  

  const BLOCKS_PER_YEAR = new BigNumber(2336000)
  const SUSHI_PER_BLOCK = new BigNumber(1000)
  //...stakedValue[i],
        apy: stakedValue[i]
          ? lodPrice
              .times(SUSHI_PER_BLOCK)
              .times(BLOCKS_PER_YEAR)
              .times(stakedValue[i].poolWeight)
              .div(stakedValue[i].totalWethValue)
          : null,
  */
  const rows = insurances.reduce<InsuranceWithStakedValue[][]>(
    (insuranceRows, insurance, i) => {
      
      const insuranceWithStakedValue = {
        ...insurance,
        liquidity: values[i] !== undefined　? new BigNumber(values[i].liquidity) : new BigNumber(0),
        utilized: values[i] !== undefined　? new BigNumber(values[i].utilized) : new BigNumber(0),
        premium: values[i] !== undefined　? new BigNumber(values[i].premium) : new BigNumber(0),
        apy: values[i] !== undefined　? new BigNumber(values[i].apy) : new BigNumber(0),
        status: values[i] !== undefined　? values[i].status : "99",
      }
      const newInsuranceRows = [...insuranceRows]
      //values[i] !== undefined? console.log(new BigNumber(values[i].liquidity).minus(new BigNumber(values[i].utiluzed)).toNumber()): console.log('no value')
      if(insuranceWithStakedValue.type === "Individual"){
        if (newInsuranceRows[newInsuranceRows.length - 1].length === 3) {
          newInsuranceRows.push([insuranceWithStakedValue])
        } else {
          newInsuranceRows[newInsuranceRows.length - 1].push(insuranceWithStakedValue)
        }
      }
      return newInsuranceRows
    },
    [[]],
  )
  
  return (
    <StyledCards>
      {!!rows[0].length ? (
        rows.map((insuranceRow, i) => (
          <StyledRow key={i}>
            {insuranceRow.map((insurance, j) => (
              <React.Fragment key={j}>
                <InsuranceCard insurance={insurance} />
                {(j === 0 || j === 1) && <StyledSpacer />}
              </React.Fragment>
            ))}
          </StyledRow>
        ))
      ) : (
        <StyledLoadingWrapper>
          <Loader />
        </StyledLoadingWrapper>
      )}
    </StyledCards>
  )
}

interface InsuranceCardProps {
  insurance: InsuranceWithStakedValue
}

const InsuranceCard: React.FC<InsuranceCardProps> = ({ insurance }) => {
  //const [startTime, setStartTime] = useState(0)
  //const [harvestable, setHarvestable] = useState(0)

  //const { account } = useWallet()
  //const { lpTokenAddress } = insurance
  const lod = useLod()
  
  const cost = Number(insurance.premium)/365 *7
  const premium = insurance.premium.div(insurance.tokenDecimal**10).multipliedBy(7).div(365).toNumber()
  //console.log(insurance.liquidity.minus(insurance.utilized).toNumber())

  const poolActive = true // startTime * 1000 - Date.now() <= 0

  return (
    <StyledCardWrapper>
      {insurance.tokenSymbol === 'LOD' && <StyledCardAccent />}
      <Card>
        <CardContent>
          <StyledContent>
            <StyledImg src={require(`../../../assets//img/${insurance.icon}.png`)} />
            <StyledTitle>{insurance.name}</StyledTitle>
            <StyledDetails>
              <StyledDetail>{insurance.description}</StyledDetail>
            </StyledDetails>
            <StyledInsight>
              <StyledInsightContent>
              <span>Weekly Cost</span>
              <span>
                {insurance.liquidity
                  ? insurance.liquidity.minus(insurance.utilized) > new BigNumber(0)
                    ? 
                    `${cost.toFixed(2)} %` : '...'
                  : 'Loading ...'}
              </span>
              </StyledInsightContent>
              <StyledInsightContent>
              <span>Capacity</span>
              <span>
                {insurance.liquidity
                  ? insurance.liquidity.minus(insurance.utilized) > new BigNumber(0)
                    ?
                      `${insurance.liquidity
                      .minus(insurance.utilized)
                      
                      .div(10 ** insurance.tokenDecimal)
                      .toNumber()
                      .toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                      } ${insurance.tokenSymbol}`
                      :
                      '...'
                  : 'Loading ...'}
              </span>
              </StyledInsightContent>
            </StyledInsight>
            <Spacer />
            <Button
              disabled={insurance.liquidity.minus(insurance.utilized) < new BigNumber(1) || insurance.status !== "0"}
              text={insurance.liquidity.minus(insurance.utilized) > new BigNumber(0) && insurance.status === "0" ? 'Select' : 'Sales Pending'}
              to={`/insurances/${insurance.id}`}
            />
          </StyledContent>
        </CardContent>
      </Card>
    </StyledCardWrapper>
  )
}

const RainbowLight = keyframes`

	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`

const StyledCardAccent = styled.div`
  background: linear-gradient(
    45deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 154, 0, 1) 10%,
    rgba(208, 222, 33, 1) 20%,
    rgba(79, 220, 74, 1) 30%,
    rgba(63, 218, 216, 1) 40%,
    rgba(47, 201, 226, 1) 50%,
    rgba(28, 127, 238, 1) 60%,
    rgba(95, 21, 242, 1) 70%,
    rgba(186, 12, 248, 1) 80%,
    rgba(251, 7, 217, 1) 90%,
    rgba(255, 0, 0, 1) 100%
  );
  background-size: 300% 300%;
  animation: ${RainbowLight} 2s linear infinite;
  border-radius: 12px;
  filter: blur(6px);
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  z-index: -1;
`

const StyledCards = styled.div`
  width: 900px;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const StyledLoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`

const StyledRow = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing[4]}px;
  flex-flow: row wrap;
  @media (max-width: 768px) {
    width: 100%;
    flex-flow: column nowrap;
    align-items: center;
  }
`

const StyledCardWrapper = styled.div`
  display: flex;
  width: calc((900px - ${(props) => props.theme.spacing[4]}px * 2) / 3);
  position: relative;
`

const StyledTitle = styled.h4`
  color: ${(props) => props.theme.color.grey[600]};
  font-size: 24px;
  font-weight: 700;
  margin: ${(props) => props.theme.spacing[2]}px 0 0;
  padding: 0;
`

const StyledContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`

const StyledSpacer = styled.div`
  height: ${(props) => props.theme.spacing[4]}px;
  width: ${(props) => props.theme.spacing[4]}px;
`

const StyledDetails = styled.div`
  margin-top: ${(props) => props.theme.spacing[2]}px;
  text-align: center;
`

const StyledDetail = styled.div`
  color: ${(props) => props.theme.color.grey[500]};
`

const StyledInsight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  border-radius: 8px;
  background: #fffdfa;
  color: #aa9584;
  width: 100%;
  margin-top: 12px;
  line-height: 32px;
  font-size: 13px;
  border: 1px solid #e6dcd5;
  text-align: center;
  padding: 0 12px;
`
const StyledInsightContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: inline;
`

const StyledImg = styled.img`
  alt: '🤔'
  height: 60px;
  width: 60px;
`

export default InsuranceCards

/*
              <span>APY</span>
              <span>
                {insurance.apy
                  ? `${insurance.apy
                      .times(new BigNumber(100))
                      .toNumber()
                      .toLocaleString('en-US')
                      .slice(0, -1)}%`
                  : 'Loading ...'}
              </span>
             
*/