 /* eslint-disable */
import { useCallback, useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import useLod from './useLod'
import { useWallet } from 'use-wallet'
import { mint, getTokenContract, getMinted } from '../lod/utils'
import  {useTransactionAdder,useTransactionReceiver}  from './useTransaction'
import {useNoticeAdder} from './useNotices'
import useBlock from './useBlock'
export const useMint = () => {
  const { account } = useWallet()
  const lod = useLod()
  const token = getTokenContract(lod,1)
  const { onAddTransaction } = useTransactionAdder()
  const { onReceiveTransaction } = useTransactionReceiver()
  const { onAddNotice } = useNoticeAdder()
  const handleMint = useCallback(
    async () => {
      try {
        const txHash = await mint(
          token,
          account,
          (tx: any) => {
            onAddTransaction(tx)
          },
          (hash: any, receipt: any) => {
            onReceiveTransaction(hash,receipt)
          },
          (content: any) => {
            onAddNotice(content)
          }
        )
        console.log(txHash)
        return txHash
      }catch (e) {
        console.log(e)
        return false
      }
    },
    [account,  token],
  )

  return { onMint: handleMint }
}


export const useMintCheck = () => {
  const { account } = useWallet()
  const [minted, setMinted] = useState(false)
  const lod = useLod()
  const block = useBlock()
  const fetchBalance = useCallback(async () => {
    const status = await getMinted(account,lod )
    setMinted(status)
  }, [lod, account])

  useEffect(() => {
    if (lod) {
      fetchBalance()
    }
  }, [lod, block, account,fetchBalance])

  return minted
}