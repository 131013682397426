 /* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react'

import { useWallet } from 'use-wallet'
import useLod from '../../hooks/useLod'

import { bnToDec } from '../../utils'
import { getEarned } from '../../lod/utils'
import { getInsurances } from '../../lod/utils'

import Context from './context'
import { Insurance } from './types'

const Insurances: React.FC = ({ children }) => {
  //const [unharvested, setUnharvested] = useState(0)

  const lod = useLod()
  //const { account } = useWallet()

  const insurances = getInsurances(lod)
  
  return (
    <Context.Provider
      value={{
        insurances
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Insurances
