 /* eslint-disable */
import { useCallback, useEffect, useState } from 'react'
import { provider } from 'web3-core'

import BigNumber from 'bignumber.js'
import { useWallet } from 'use-wallet'
import { Contract } from 'web3-eth-contract'
import {
  getInsurances,
  getAllFarmStatus,
  getUnderlying,
  getMarketContract,
  getGaugeContract,
  getUnlock
} from '../lod/utils'
import useLod from './useLod'
import useBlock from './useBlock'

/*
export interface StakedValue {
  id: BigNumber
  startTime: BigNumber
  status: BigNumber
}

uint256 id;
        uint256 startTime;
        uint256 endTime;
        uint256 amount;
        address insured;
        bool status;
*/
const useMyFarms = () => {
  const [balances, setBalance] = useState([])
  const { account }: { account: string; ethereum: provider } = useWallet()
  const lod = useLod()
  const insurances = getInsurances(lod)
  /*
  const fetchAllValue = useCallback(async () => {
    let values:any[] = []
    for(let i = 0; i < insurances.length; i++){
      const marketContract = await getMarketContract(lod, insurances[i].pid)
      let insuranceData = await getMyInsurances(marketContract, insurances[i].pid, account)
      console.log(insuranceData) 
      values.push(insuranceData)
    }
    values.concat()
    console.log(values)
    setBalance(values)
  }, [account, lod, insurances])
  */
  /*
 const fetchAllValue = useCallback(async () => {
  let values:any[] = []
  for(let i = 0; i < insurances.length; i++){
    const marketContract = await getMarketContract(lod, insurances[i].pid)
    const gauge = getGaugeContract(lod,insurances[i].pid)
    let poolData:any = await getMyPool(marketContract, gauge,account)
    if(poolData.balance !== 0){
      poolData.id = insurances[i].id
      poolData.tokenDecimal = insurances[i].tokenDecimal
      poolData.name = insurances[i].name
      poolData.icon = insurances[i].icon
      poolData.tokenSymbol = insurances[i].tokenSymbol
      values.push(poolData)
    }
  }
  */
  const fetchAllValue = useCallback(async () => {
    let values:any[] = []
    for(let i = 0; i < insurances.length; i++){

      let poolData:any = await getAllFarmStatus(lod,insurances[i].pid,account)
       
      if(poolData.staked !== "0"){
        poolData.apy = poolData.lowAPY * poolData.working_balance
        poolData.id = insurances[i].id
        poolData.tokenDecimal = insurances[i].tokenDecimal
        poolData.name = insurances[i].name
        poolData.icon = insurances[i].icon
        poolData.type = insurances[i].type
        poolData.tokenSymbol = insurances[i].tokenSymbol
        values.push(poolData)
      }
    }
  if(values.length === 0){values=['nodata']}
    setBalance(values)
  }, [account, lod, insurances])

  useEffect(() => {
    if (account &&  lod) {
      fetchAllValue()
    }
  }, [account, setBalance, lod])

  return balances
}

export default useMyFarms
