 /* eslint-disable */
import { useCallback } from 'react'
import BigNumber from 'bignumber.js'
import useLod from './useLod'
import { useWallet } from 'use-wallet'
import  {useTransactionAdder,useTransactionReceiver}  from './useTransaction'
import {useNoticeAdder} from './useNotices'
import { withdraw, getGaugeContract } from '../lod/utils'
//To unstake LP token
const useWithdraw = (pid: number, tokenDecimal:number) => {
  const { account } = useWallet()
  const lod = useLod()
  const market = getGaugeContract(lod,pid)
  const { onAddTransaction } = useTransactionAdder()
  const { onReceiveTransaction } = useTransactionReceiver()
  const { onAddNotice } = useNoticeAdder()
  const handleWithdraw = useCallback(
    async (amount: any) => {
      try {
        const txHash = await withdraw(
          market, 
          new BigNumber(amount).times(new BigNumber(10).pow(tokenDecimal)).toString(), 
          account,
          (tx: any) => {
            onAddTransaction(tx)
          },
          (hash: any, receipt: any) => {
            onReceiveTransaction(hash,receipt)
          },
          (content: any) => {
            onAddNotice(content)
          }
        )
        return txHash
      } catch (e) {
        return false
      }
    },
    [market, pid, account,lod ],
  )

  return { onWithdraw: handleWithdraw }
}

export default useWithdraw
