 /* eslint-disable */
import { useCallback } from 'react'
import BigNumber from 'bignumber.js'
import useLod from './useLod'
import { useWallet } from 'use-wallet'
import  {useTransactionAdder,useTransactionReceiver}  from './useTransaction'
import {useNoticeAdder} from './useNotices'
import { stake, getMarketContract } from '../lod/utils'

const useStake = (pid: number, tokenDecimal:number) => {
  const { account } = useWallet()
  const lod = useLod()
  const { onAddTransaction } = useTransactionAdder()
  const { onReceiveTransaction } = useTransactionReceiver()
  const { onAddNotice } = useNoticeAdder()
  const handleStake = useCallback(
    async (amount: any) => {
      //onAddTransaction({})
      try {
        const txHash = await stake(
          getMarketContract(lod,pid),
          new BigNumber(amount).times(new BigNumber(10).pow(tokenDecimal)).toString(),
          account,
          (tx: any) => {
            onAddTransaction(tx)
          },
          (hash: any, receipt: any) => {
            onReceiveTransaction(hash,receipt)
          },
          (content: any) => {
            onAddNotice(content)
          }
        )
        console.log(txHash)
        return txHash
      }catch (e) {
        console.log(e)
        return false
    }
    },
    [account, pid, lod],
  )

  return { onStake: handleStake }
}

export default useStake
