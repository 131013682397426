import React, { useCallback, useEffect, useReducer } from 'react'
import Web3 from 'web3'
import { provider } from 'web3-core'
import { useWallet } from 'use-wallet'
import { TransactionReceipt } from 'web3-core'
import Context from './context'
import reducer, {
  initialState,
  setTransactions,
  addTransaction,
  receiveTxReceipt
} from './reducer'

import { Transaction, TransactionsMap } from './types'
import useBlock from '../../hooks/useBlock'
import {useNoticeAdder} from '../../hooks/useNotices'

const TransactionsProvider: React.FC = ({ children }) => {
  const [{ initialized, transactions }, dispatch] = useReducer(reducer, initialState)
  const { ethereum }: { ethereum: provider } = useWallet()
  const block = useBlock()
  const { onAddNotice } = useNoticeAdder()

  const handleAddTransaction = useCallback((tx: Transaction) => {
    dispatch(addTransaction(tx))
  }, [dispatch])

  const handleReceiveTransaction = useCallback((txHash: string, receipt:TransactionReceipt) => {
    dispatch(receiveTxReceipt(txHash, receipt))
  }, [dispatch])

  const fetchTransactions = useCallback(async () => {
    try {
      const txsRaw = localStorage.getItem('transactions')
      const txs = JSON.parse(txsRaw) as TransactionsMap || {}
      dispatch(setTransactions(txs))
    } catch (e) {
      console.log(e)
    }
  }, [dispatch])

  useEffect(() => {
    if (initialized) {
      localStorage.setItem('transactions', JSON.stringify(transactions))
    }
  }, [initialized, transactions])

  useEffect(() => {
    fetchTransactions()
  }, [fetchTransactions])

  useEffect(() => {
    if (!ethereum) return
    const web3 = new Web3(ethereum)
    Object.keys(transactions)
      .map((txHash) => transactions[txHash])
      .filter((tx) => !tx.receipt && tx.type ==="Transaction")
      .forEach(async (tx) => {
        await web3.eth
          .getTransactionReceipt(tx.hash)
          .then(async (receipt) => {
            if (receipt) {
              handleReceiveTransaction(receipt.transactionHash, receipt)
              onAddNotice({
                type:"Notice",
                description: tx.description,
                hash: receipt.transactionHash,
                show: true,
                success: receipt.status,
                removeAfterMs: 15000,
              })
            }  
          })
          .catch(error => {
            console.error(`failed to check transaction hash: ${tx.hash}`, error)
          })
      })
  }, [ethereum, transactions, block, dispatch, onAddNotice,handleReceiveTransaction ])

  return (
    <Context.Provider value={{
      transactions,
      onAddTransaction: handleAddTransaction,
      onReceiveTransaction: handleReceiveTransaction,
    }}>
      {children}
    </Context.Provider>
  )
}



export default TransactionsProvider