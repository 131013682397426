 /* eslint-disable */

import React, { useCallback, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch,Redirect } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { UseWalletProvider } from 'use-wallet'
import DisclaimerModal from './components/DisclaimerModal'
import MobileMenu from './components/MobileMenu'
import TopBar from './components/TopBar'
import Notice from './components/Notice'
import NoticeProvider from './contexts/Notices'
import InsurancesProvider from './contexts/Insurances'
import ModalsProvider from './contexts/Modals'
import TransactionProvider from './contexts/Transactions'
import LodProvider from './contexts/LodProvider'
import useModal from './hooks/useModal'
import theme from './theme'
import Insurances from './views/Insurances'
import Pools from './views/Pools'
import DAO from './views/DAO'
import Manage from './views/Manage'
//import Stake from './views/Stake'

const App: React.FC = () => {
  const [mobileMenu, setMobileMenu] = useState(false)

  const handleDismissMobileMenu = useCallback(() => {
    setMobileMenu(false)
  }, [setMobileMenu])

  const handlePresentMobileMenu = useCallback(() => {
    setMobileMenu(true)
  }, [setMobileMenu])

  return (
    <Providers>
      <Router>
        <TopBar onPresentMobileMenu={handlePresentMobileMenu} />
        <MobileMenu onDismiss={handleDismissMobileMenu} visible={mobileMenu} />
        <Notice/>
        <Switch>
          <Route path="/insurances" >
            <Insurances />
          </Route>
          <Route path="/underwriting" >
            <Pools />
          </Route>
          <Route path="/dao" >
            <DAO />
          </Route>
          <Route path="/dashboard" >
            <Manage />
          </Route>
          <Route path="/" >
            <Redirect to="/insurances" />
          </Route>
        </Switch>
      </Router>
      <Disclaimer />
    </Providers>
  )
}

/**Rinkeby needs to be changed */
const Providers: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <UseWalletProvider
        chainId={4}
        connectors={{}}
      >
        <LodProvider>
          <NoticeProvider>
            <TransactionProvider>
              <InsurancesProvider>
                <ModalsProvider>
                  {children}
                </ModalsProvider>
              </InsurancesProvider>
            </TransactionProvider>
          </NoticeProvider>
        </LodProvider>
      </UseWalletProvider>
    </ThemeProvider>
  )
}

const Disclaimer: React.FC = () => {
  const markSeen = useCallback(() => {
    localStorage.setItem('disclaimer', 'seen')
  }, [])

  const [onPresentDisclaimerModal] = useModal(
    <DisclaimerModal onConfirm={markSeen} />,
  )

  useEffect(() => {
    const seenDisclaimer = true // localStorage.getItem('disclaimer')
    if (!seenDisclaimer) {
      onPresentDisclaimerModal()
    }
  }, [onPresentDisclaimerModal])

  return <div />
}

export default App

/*

          <Route path="/staking">
            <Stake />
          </Route>
*/
