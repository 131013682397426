 /* eslint-disable */
import React, { useEffect, useMemo,useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useWallet } from 'use-wallet'
import { provider } from 'web3-core'
import PageHeader from '../../components/PageHeader'
import Spacer from '../../components/Spacer'
import useInsurance from '../../hooks/useInsurance'

import useLod from '../../hooks/useLod'
//import { getMasterChefContract } from '../../lod/utils'
import { getContract } from '../../utils/erc20'
import StakeFund from './components/StakeFund'
import StakeLP from './components/StakeLP'
import Status from './components/Status'
import { getMarketContract } from '../../lod/utils'

const Pool: React.FC = () => {

  const { poolId } = useParams()
  const lod = useLod()
  
  let pool  = useInsurance(poolId) || {
    pid: 0,
    description: '',
    tokenName: '',
    marketAddress: '',
    vaultAddress: '',
    tokenAddress: '',
    gaugeAddress: '',
    tokenSymbol: '',
    tokenDecimal: 18,
    name: '',
    icon: '',
    base: 0,
    multiple: 0,
    fee:0
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  

  const { ethereum } = useWallet()
  const tokenContract = useMemo(() => {
    return getContract(ethereum as provider, pool.tokenAddress)
  }, [ethereum, pool.tokenAddress])
  const vaultContract = useMemo(() => {
    return getContract(ethereum as provider, pool.vaultAddress)
  }, [ethereum, pool.tokenAddress])
  
  /*
  const tokenContract = useMemo(() => {
    return getTokenContract(lod,pool.pid)
  }, [lod, pool.pid])
  */
  //const tokenContract = getMarketContract(lod,pool.pid)
  const marketContract = getMarketContract(lod,pool.pid)
  //console.log(marketContract)
  /*
  const marketContract = useMemo(() => {
    return getContract(ethereum as provider, pool.marketAddress)
  }, [ethereum, pool.marketAddress])

  const tName = useMemo(() => {
    return tokenName.toUpperCase()
  }, [pool.lpToken])
  */
  return (
    <>
      {pool.pid !== 0?(
      <>
      <PageHeader
        icon={pool.icon}
        subtitle={pool.description}
        title={pool.name}
      />
      <StyledPool>
        <StyledCardsWrapper>
          <StyledCardWrapper>
          <StakeFund
              vaultContract={vaultContract}
              tokenContract={tokenContract}
              pool={pool}
            />
          </StyledCardWrapper>
          <Spacer />
          <StyledCardWrapper>
            <StakeLP
              marketContract={marketContract}
              
              pool={pool}
            />
          </StyledCardWrapper>
        </StyledCardsWrapper>
        <Spacer size="lg" />
        <StyledInfo>
          ⭐️ The liquidity provided will be locked until there is a request for withdrawal. <br/>
          Withdrawal is allowable as long as the market is trading and the liquidity is not utilized. 
          <br/>The withdrawal is subjected to a grace period.
        </StyledInfo>
        <Spacer size="lg" />
      </StyledPool>
      </>
      ):(
      <StyledPool>
        <StyledInfo>
          page not found 
        </StyledInfo>
        <Spacer size="lg" />
      </StyledPool>  
      )}
    </>
  )
}

const StyledPool = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const StyledCardsWrapper = styled.div`
  display: flex;
  width: 768px;
  @media (max-width: 768px) {
    width: 100%;
    flex-flow: column nowrap;
    align-items: center;
  }
`

const StyledCardWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 80%;
  }
`

const StyledInfo = styled.h3`
  color: ${(props) => props.theme.color.white};
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;
`

export default Pool
