/* eslint-disable */
import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import PoolTemplateAbi from './lib/abi/PoolTemplate.json'
import IndexAbi from './lib/abi/IndexTemplate.json'
import { pid } from 'process'
import Web3 from 'web3'
import useTransactionAdder from '../hooks/useTransaction'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const GAS_LIMIT = {
  STAKING: {
    DEFAULT: 200000,
    SNX: 850000,
  },
}

export const getInsureAddress = (lod) => {
  return lod && lod.contracts && lod.contracts.insureAddress
}
export const getItemLabel = (lod, value) => {
  let target
  lod
    ? (target = lod.contracts.pools.filter((obj) => {
        return obj.IDs.value === value
      }))
    : console.log(target)
  return target
}

export const getInsureContract = (lod) => {
  return lod && lod.contracts && lod.contracts.insuretoken
}

export const getTokenAddress = (lod, _pid) => {
  let target
  lod
    ? (target = lod.contracts.pools.filter((obj) => {
        return obj.pid === _pid
      }))
    : console.log(target)
  return (
    lod && lod.contracts.pools && target.length > 0 && target[0].tokenAddress
  )
}

export const getMarketAddress = (lod, _pid) => {
  let target
  lod
    ? (target = lod.contracts.pools.filter((obj) => {
        return obj.pid === _pid
      }))
    : console.log(target)
  return (
    lod && lod.contracts.pools && target.length > 0 && target[0].marketAddress
  )
}

export const getMarketContract = (lod, _pid) => {
  let target
  lod
    ? (target = lod.contracts.pools.filter((obj) => {
        return obj.pid === _pid
      }))
    : console.log(target)
  return (
    lod && lod.contracts.pools && target.length > 0 && target[0].marketContract
  )
}

export const getTokenContract = (lod, _pid) => {
  let target
  lod
    ? (target = lod.contracts.pools.filter((obj) => {
        return obj.pid === _pid
      }))
    : console.log(target)
  return (
    lod && lod.contracts.pools && target.length > 0 && target[0].tokenContract
  )
}

export const getParemeterContract = (lod, _pid) => {
  let target
  lod
    ? (target = lod.contracts.pools.filter((obj) => {
        return obj.pid === _pid
      }))
    : console.log(target)
  return (
    lod &&
    lod.contracts.pools &&
    target.length > 0 &&
    target[0].parameterContract
  )
}

export const getGaugeContract = (lod, _pid) => {
  let target
  lod
    ? (target = lod.contracts.pools.filter((obj) => {
        return obj.pid === _pid
      }))
    : console.log(target)
  return (
    lod && lod.contracts.pools && target.length > 0 && target[0].gaugeContract
  )
}

export const getMinterContract = (lod) => {
  return lod && lod.contracts.minterContract
}

export const getGaugeAddress = (lod, _pid) => {
  let target
  lod
    ? (target = lod.contracts.pools.filter((obj) => {
        return obj.pid === _pid
      }))
    : console.log(target)
  return (
    lod && lod.contracts.pools && target.length > 0 && target[0].gaugeAddress
  )
}

export const getInsurances = (lod) => {
  return lod
    ? lod.contracts.pools.map(
        ({
          id,
          pid,
          name,
          icon,
          description,
          url,
          payout,
          tokenContract,
          marketContract,
          vaultContract,
          gaugeAddress,
          paremeterContract,
          tokenAddress,
          vaultAddress,
          marketAddress,
          tokenDecimal,
          lockup,
          withdrawGrace,
          IDs,
          type,
          tokenSymbol,
          base,
          multiple,
          fee,
        }) => ({
          id,
          pid,
          name,
          icon,
          description,
          url,
          payout,
          tokenContract,
          marketContract,
          vaultContract,
          gaugeAddress,
          paremeterContract,
          tokenAddress,
          marketAddress,
          vaultAddress,
          tokenDecimal,
          lockup,
          withdrawGrace,
          IDs,
          type,
          tokenSymbol,
          base,
          multiple,
          fee,
        }),
      )
    : []
}

export const getDecimal = (lod, _pid) => {
  let target
  lod
    ? (target = lod.contracts.pools.filter((obj) => {
        return obj.pid === _pid
      }))
    : console.log(target)
  return (
    lod && lod.contracts.pools && target.length > 0 && target[0].tokenDecimal
  )
}

export const getEarned = async (market, account) => {
  return market.methods.pendingPremium(account).call()
}

export const getUnlock = async (market, account) => {
  try {
    return market.methods.withdrawalReq(account).call()
  } catch (e) {
    return '0'
  }
}

export const getAllPoolStatus = async (lod, pid, type, fee) => {
  const marketContract = await getMarketContract(lod, pid)
  const marketAddress = await getMarketAddress(lod, pid)
  const parameterContract = await getParemeterContract(lod, pid)
  let liquidity = await marketContract.methods.totalLiquidity().call()
  const exchange = await marketContract.methods.rate().call()
  const lockup = await parameterContract.methods.getLockup(marketAddress).call()
  const withdrawGrace = await parameterContract.methods
    .getWithdrawable(marketAddress)
    .call()
  let available
  let utilized
  let apy = 0
  let premium = 0
  let leverage = 0
  let status = '0'
  if (type === 'Individual') {
    available = await marketContract.methods.availableBalance().call()

    utilized = new BigNumber(liquidity).minus(new BigNumber(available))
    status = await marketContract.methods.marketStatus().call()

    apy = await parameterContract.methods
      .getPremium(
        utilized.toString(),
        (86400 * 365).toString(),
        liquidity,
        0,
        marketAddress,
      )
      .call()

    premium = (apy / liquidity) * 100
    apy = (apy / liquidity) * 90
    const paused = marketContract.methods.paused().call()
    !paused ? (status = '2') : (status = status)
  }
  if (type === 'Index') {
    let beforediv = 0

    const pool = await lod.contracts.pools.filter((pools) => {
      return pools.pid === pid
    })
    for (let i of pool[0].pools) {
      const contract = await new lod.web3.eth.Contract(
        PoolTemplateAbi.abi,
        i.address,
      )
      const pooldata = await contract.methods.indexes(marketAddress).call()
      const allocated = await pooldata.credit
      const liquid = await contract.methods.totalLiquidity().call()
      const avail = await contract.methods.availableBalance().call()
      const util = new BigNumber(liquid).minus(new BigNumber(avail))
      const localstat = await contract.methods.marketStatus().call()
      if (localstat !== '0') {
        status = localstat
      }

      const localapy = await parameterContract.methods
        .getPremium(
          util.toString(),
          (86400 * 365).toString(),
          liquid,
          0,
          i.address,
        )
        .call()

      beforediv = beforediv + (localapy * 90 * allocated) / liquid
    }
    //const tac = await marketContract.methods.totalAllocatedCredit().call()
    available =
      liquidity > 0 ? await marketContract.methods.withdrawable().call() : 0
    utilized = await new BigNumber(liquidity).minus(new BigNumber(available))
    apy = beforediv / liquidity
    //apy = ((beforediv / tac) * pool[0].leverage) / 1000
    leverage = (await marketContract.methods.leverage().call()) / 1000
    const paused = await marketContract.methods.paused().call()

    paused ? (status = '2') : (status = status)
  }
  if (type === 'CDS') {
    available = liquidity
    utilized = new BigNumber(0)
    let before = new BigNumber(0)
    const pool = await lod.contracts.pools.filter((pools) => {
      return pools.pid === pid
    })
    for (let i of pool[0].indexes) {
      const contract = await new lod.web3.eth.Contract(IndexAbi.abi, i)
      const tvl = await contract.methods.totalLiquidity().call()
      const fee = await parameterContract.methods.getFee2(tvl, i).call()
      before = before.plus(fee)
    }
    apy = Number(before.div(liquidity))
    const paused = marketContract.methods.paused().call()
    !paused ? (status = '2') : (status = status)
  }
  liquidity = new BigNumber(liquidity)
  return {
    utilized,
    liquidity,
    apy,
    premium,
    status,
    leverage,
    exchange,
    lockup,
    withdrawGrace,
  }
}

export const getAllFarmStatus = async (lod, pid, account) => {
  const marketContract = await getMarketContract(lod, pid)
  const marketAddress = await getMarketAddress(lod, pid)
  const gaugeContract = await getGaugeContract(lod, pid)
  const gaugeAddress = await getGaugeAddress(lod, pid)
  const gcContract = await lod.contracts.gc
  const insureContract = await lod.contracts.insuretoken
  const decimal = await getDecimal(lod, pid)
  const exchange = await marketContract.methods.rate().call()
  const virtual_price = exchange / 1e18
  const relative_weight =
    (await gcContract.methods.gauge_relative_weight(gaugeAddress, 0).call()) /
    1e18
  const working_scale = 10 ** decimal

  const inflation_rate = (await insureContract.methods.rate().call()) / 1e18

  const working_supply =
    (await gaugeContract.methods.working_supply().call()) / working_scale
  const inflation_share =
    working_supply !== 0 ? inflation_rate * 31536000 * relative_weight : 0
  const insure_price = 0.3

  const inflation_share_value = inflation_share * insure_price
  const stake_value = working_supply * virtual_price
  const highAPY = (inflation_share_value / stake_value) * 100
  const staked = await gaugeContract.methods.balanceOf(account).call()
  const unclaimed = await gaugeContract.methods.claimable_tokens(account).call()

  let status = '0'
  const lowAPY = highAPY * 0.4
  let working_balance = await gaugeContract.methods
    .working_balances(account)
    .call()

  working_balance = working_balance / 0.4 / staked

  return { staked, unclaimed, status, working_balance, lowAPY, highAPY }
}

export const getAllStatus = async (lod, pid) => {
  const marketContract = getMarketContract(lod, pid)
  const liquidity = await marketContract.methods.totalLiquidity().call()
  const available = await marketContract.methods.availableBalance().call()
  const utilized = new BigNumber(liquidity).minus(new BigNumber(available))

  const status = await marketContract.methods.marketStatus().call()
  return { utilized, liquidity, status }
}

export const approve = async (token, market, account, add, receive, notice) => {
  return token.methods
    .approve(market, ethers.constants.MaxUint256)
    .send({ from: account })
    .on('transactionHash', (transactionHash) => {
      add({
        type: 'Transaction',
        description: 'Approval',
        hash: transactionHash,
        receipt: null,
      })
    })
    .on('receipt', (receipt) => {
      console.log(receipt)
      receive(receipt.transactionHash, receipt)
      notice({
        type: 'Notice',
        description: 'Approval',
        hash: receipt.transactionHash,
        show: true,
        success: receipt.status,
        removeAfterMs: 15000,
      })
      return receipt
    })
}

export const deposit = async (
  market,
  amount,
  account,
  add,
  receive,
  notice,
) => {
  return market.methods
    .deposit(
      amount,
      account, //new BigNumber(amount).times(new BigNumber(10).pow(18)).toString(),
    )
    .send({ from: account })
    .on('transactionHash', (transactionHash) => {
      console.log(transactionHash)
      add({
        type: 'Transaction',
        description: 'Stake LP Token',
        hash: transactionHash,
        receipt: null,
      })
    })
    .on('receipt', (receipt) => {
      console.log(receipt)
      receive(receipt.transactionHash, receipt)
      notice({
        type: 'Notice',
        description: 'Stake LP Token',
        hash: receipt.transactionHash,
        show: true,
        success: receipt.status,
        removeAfterMs: 15000,
      })
      return receipt
    })
}

export const stake = async (market, amount, account, add, receive, notice) => {
  console.log(market)
  return market.methods
    .deposit(
      amount, //new BigNumber(amount).times(new BigNumber(10).pow(18)).toString(),
    )
    .send({ from: account })
    .on('transactionHash', (transactionHash) => {
      console.log(transactionHash)
      add({
        type: 'Transaction',
        description: 'Deposit collateral',
        hash: transactionHash,
        receipt: null,
      })
    })
    .on('receipt', (receipt) => {
      console.log(receipt)
      receive(receipt.transactionHash, receipt)
      notice({
        type: 'Notice',
        description: 'Deposit collateral',
        hash: receipt.transactionHash,
        show: true,
        success: receipt.status,
        removeAfterMs: 15000,
      })
      return receipt
    })
}

export const unstake = async (
  market,
  amount,
  account,
  add,
  receive,
  notice,
) => {
  return market.methods
    .withdraw(amount)
    .send({ from: account })
    .on('transactionHash', (transactionHash) => {
      console.log(transactionHash)
      add({
        type: 'Transaction',
        description: 'Withdraw collateral',
        hash: transactionHash,
        receipt: null,
      })
    })
    .on('receipt', (receipt) => {
      console.log(receipt)
      receive(receipt.transactionHash, receipt)
      notice({
        type: 'Notice',
        description: 'Withdraw collateral',
        hash: receipt.transactionHash,
        show: true,
        success: receipt.status,
        removeAfterMs: 15000,
      })
      return receipt
    })
}

export const withdraw = async (
  market,
  amount,
  account,
  add,
  receive,
  notice,
) => {
  return market.methods
    .withdraw(amount)
    .send({ from: account })
    .on('transactionHash', (transactionHash) => {
      console.log(transactionHash)
      add({
        type: 'Transaction',
        description: 'Unstake LP Token',
        hash: transactionHash,
        receipt: null,
      })
    })
    .on('receipt', (receipt) => {
      console.log(receipt)
      receive(receipt.transactionHash, receipt)
      notice({
        type: 'Notice',
        description: 'Unstake LP Token',
        hash: receipt.transactionHash,
        show: true,
        success: receipt.status,
        removeAfterMs: 15000,
      })
      return receipt
    })
}

export const unlock = async (market, id, account, add, receive, notice) => {
  return market.methods
    .unlock(id)
    .send({ from: account })
    .on('transactionHash', (transactionHash) => {
      console.log(transactionHash)
      add({
        type: 'Transaction',
        description: 'Unlock',
        hash: transactionHash,
        receipt: null,
      })
    })
    .on('receipt', (receipt) => {
      console.log(receipt)
      receive(receipt.transactionHash, receipt)
      notice({
        type: 'Notice',
        description: 'Unlock',
        hash: receipt.transactionHash,
        show: true,
        success: receipt.status,
        removeAfterMs: 15000,
      })
      return receipt
    })
}

export const requestWithdraw = async (
  market,
  amount,
  account,
  add,
  receive,
  notice,
) => {
  return market.methods
    .requestWithdraw(amount)
    .send({ from: account })
    .on('transactionHash', (transactionHash) => {
      console.log(transactionHash)
      add({
        type: 'Transaction',
        description: 'Request Withdrawal',
        hash: transactionHash,
        receipt: null,
      })
    })
    .on('receipt', (receipt) => {
      console.log(receipt)
      receive(receipt.transactionHash, receipt)
      notice({
        type: 'Notice',
        description: 'Request Withdrawal',
        hash: receipt.transactionHash,
        show: true,
        success: receipt.status,
        removeAfterMs: 15000,
      })
      return receipt
    })
}

export const harvest = async (market, gauge, account, add, receive, notice) => {
  return market.methods
    .mint(gauge)
    .send({ from: account })
    .on('transactionHash', (transactionHash) => {
      console.log(transactionHash)
      add({
        type: 'Transaction',
        description: 'Insure Claim',
        hash: transactionHash,
        receipt: null,
      })
    })
    .on('receipt', (receipt) => {
      console.log(receipt)
      receive(receipt.transactionHash, receipt)
      notice({
        type: 'Notice',
        description: 'Insure Claim',
        hash: receipt.transactionHash,
        show: true,
        success: receipt.status,
        removeAfterMs: 15000,
      })
      return receipt
    })
}

export const mint = async (token, account, add, receive, notice) => {
  return token.methods
    .mint()
    .send({ from: account })
    .on('transactionHash', (transactionHash) => {
      console.log(transactionHash)
      add({
        type: 'Transaction',
        description: 'Mint faucet',
        hash: transactionHash,
        receipt: null,
      })
    })
    .on('receipt', (receipt) => {
      console.log(receipt)
      receive(receipt.transactionHash, receipt)
      notice({
        type: 'Notice',
        description: 'Mint faucet',
        hash: receipt.transactionHash,
        show: true,
        success: receipt.status,
        removeAfterMs: 15000,
      })
      return receipt
    })
}

export const claim = async (market, id, account, add, receive, notice) => {
  return market.methods
    .redeem(id)
    .send({ from: account })
    .on('transactionHash', (transactionHash) => {
      console.log(transactionHash)
      add({
        type: 'Transaction',
        description: 'Redeem Insurance',
        hash: transactionHash,
        receipt: null,
      })
    })
    .on('receipt', (receipt) => {
      console.log(receipt)
      receive(receipt.transactionHash, receipt)
      notice({
        type: 'Notice',
        description: 'Redeem Insurance',
        hash: receipt.transactionHash,
        show: true,
        success: receipt.status,
        removeAfterMs: 15000,
      })
      return receipt
    })
}

export const getUnderlying = async (market, gauge, account) => {
  try {
    const unstaked = await market.methods.balanceOf(account).call()
    const staked = await gauge.methods.balanceOf(account).call()
    const rate = await market.methods.rate().call()
    const amount = Number(unstaked) + Number(staked)
    const stakedVal = (amount * rate) / 1e18
    return { stakedVal, unstaked, staked, amount }
  } catch (e) {
    console.log(e)
  }
}

export const getCost = async (market, span, amount) => {
  try {
    const premium = await market.methods.getPremium(amount, span).call()
    //const fee = await market.methods.getFee(amount, span).call()
    return new BigNumber(premium)
  } catch (e) {
    console.log(e)
    return '0'
  }
}

export const getInsured = async (
  market,
  amount,
  maxCost,
  endTime,
  item,
  account,
  add,
  receive,
  notice,
) => {
  //console.log(amount.toNumber(), maxCost.toNumber(), convertUnixToDate(endTime))
  return market.methods
    .insure(amount, maxCost, endTime, item)
    .send({ from: account })
    .on('transactionHash', (transactionHash) => {
      console.log(transactionHash)
      add({
        type: 'Transaction',
        description: 'Get Insured',
        hash: transactionHash,
        receipt: null,
      })
    })
    .on('receipt', (receipt) => {
      console.log(receipt)
      receive(receipt.transactionHash, receipt)
      notice({
        type: 'Notice',
        description: 'Get Insured',
        hash: receipt.transactionHash,
        show: true,
        success: receipt.status,
        removeAfterMs: 15000,
      })
      return receipt
    })
}
/*
export const getMyInsurances = async (market, pid, account) => {
  try {
    const count = await market.methods.getInsuranceCount(account).call()
    console.log(market, count)
    let insurances = []
    for (let i = 0; i < count; i++) {
      let detail = await market.methods.insuranceHoldings(account, i).call()
      detail.insurance = pid
      insurances.push(detail)
    }
    return insurances
  } catch {
    return null
  }
}
*/
export const getMyPool = async (market, gauge, account) => {
  try {
    let detail = new Object()
    const balance = await market.methods.balanceOf(account).call()
    const value = await market.methods.valueOfUnderlying(account).call()
    const rate = await market.methods.rate().call()
    const staked = await gauge.methods.balanceOf(account).call()
    const stakedVal = (staked * rate) / 1e18
    const amount = Number(value) + stakedVal
    detail.balance = Number(balance) + Number(staked)
    detail.staked = Number(staked)
    detail.underlying = amount
    //detail.earned = await getEarned(market, account)

    return detail
  } catch {
    return null
  }
}

export const getInsuranceById = async (
  market,
  parameter,
  marketAddress,
  slot,
) => {
  try {
    let detail = await market.methods.insurances(slot).call()
    detail.status = await market.methods.marketStatus().call()
    detail.pendingEnd = await market.methods.pendingEnd().call()
    detail.grace = await parameter.methods.getGrace(marketAddress).call()
    detail.incident = await market.methods.incident().call()
    detail.payoutTargets = await market.methods.getPayoutTargets().call()
    return detail
  } catch {
    return null
  }
}

export const getMyInsurance = async (
  market,
  parameter,
  slot,
  account,
  marketAddress,
) => {
  try {
    const id = await market.methods.insuranceHoldings(account, slot).call()
    let detail = await market.methods.insurances(id).call()

    detail.marketstat = await market.methods.marketStatus().call()
    detail.pendingEnd = await market.methods.pendingEnd().call()
    detail.grace = await parameter.methods.getGrace(marketAddress).call()
    detail.incident = await market.methods.incident().call()
    detail.payoutTargets = await market.methods.getPayoutTargets().call()
    return detail
  } catch {
    return null
  }
}

export const getMinted = async (account, lod) => {
  try {
    const token = getTokenContract(lod, 1)
    const result = await token.methods.minted(account).call()
    return result
  } catch {
    return null
  }
}

export const getMyInsuranceCount = async (market, account) => {
  try {
    const count = await market.methods.getInsuranceCount(account).call()
    return count
  } catch {
    return null
  }
}

export const getAllInsuranceCount = async (market) => {
  try {
    const count = await market.methods.allInsuranceCount().call()
    return count
  } catch (e) {
    console.log(e)
    return null
  }
}

export const getUnclaimed = async (market, account) => {
  try {
    let sum = 0
    const rounds = await market.methods.round().call()
    for (let i = 0; i < rounds; i++) {
      const amount = await market.methods.checkReward(i, account).call()
      sum += amount
    }
    return new BigNumber(sum)
  } catch {
    return new BigNumber(0)
  }
}

export const getRemainingReward = async (lod, treasury) => {
  try {
    let reward = await lod.methods.balanceOf(treasury).call()
    return new BigNumber(reward)
  } catch {
    return new BigNumber(0)
  }
}

export const convertUnixToDate = (UNIX_timestamp) => {
  let a = new Date(UNIX_timestamp * 1000)
  let ten = (i) => {
    return (i < 10 ? '0' : '') + i
  }
  let months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  let year = a.getUTCFullYear()
  let month = months[a.getUTCMonth()]
  let date = ten(a.getUTCDate())
  let hour = ten(a.getUTCHours())
  let min = ten(a.getUTCMinutes())
  let time = date + ' ' + month + ' ' + year //+ ' UTC'
  return time
}
