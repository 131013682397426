 /* eslint-disable */
import BigNumber from 'bignumber.js'
import React, { useCallback, useState,useEffect } from 'react'
import styled from 'styled-components'
import { useWallet } from 'use-wallet'
import { Contract } from 'web3-eth-contract'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import CardIcon from '../../../components/CardIcon'
import IconButton from '../../../components/IconButton'
import { AddIcon,RemoveIcon } from '../../../components/icons'
import Label from '../../../components/Label'
import StatusValue from '../../../components/StatusValue'
import useAllowance from '../../../hooks/useAllowance'
import useApprove from '../../../hooks/useApprove'
import useModal from '../../../hooks/useModal'
import useRequestWithdraw from '../../../hooks/useRequestWithdraw'
import useStake from '../../../hooks/useStake'
import useUnderlyingBalance from '../../../hooks/useUnderlyingBalance'
import useTokenBalance from '../../../hooks/useTokenBalance'
//import useEarnings from '../../../hooks/useEarnings'
import useUnstake from '../../../hooks/useUnstake'
import { getDisplayBalance } from '../../../utils/formatBalance'
import DepositModal from './DepositModal'
import WithdrawModal from './WithdrawModal'
import WithdrawRequestModal from './WithdrawRequestModal'
import useLod from '../../../hooks/useLod'
import useStatus from '../../../hooks/useStatus'
import useReward from '../../../hooks/useReward'
import useUnlock from '../../../hooks/useUnlock'
import { getMarketContract,getUnlock } from '../../../lod/utils'
import Countdown, { CountdownRenderProps } from 'react-countdown'
import { provider } from 'web3-core'
import { StyledDescription } from '../../DAO/DAO'

interface StakeProps {
  tokenContract: Contract
  vaultContract: Contract
  pool: any
}

const StakeFund: React.FC<StakeProps> = ({ vaultContract,tokenContract, pool}) => {
  const [pendingTx, setPendingTx] = useState(false)
  const [requestedApproval, setRequestedApproval] = useState(false)
  const lod = useLod()
  const values = useStatus(pool.type, pool.pid) 
  
  
  let status 
  if(values.status === '0'){
      status = "Trading"
  }else if(values.status === '1'){
      status = "Payingout"
  }else if (values.status === "2"){
      status = "Paused"
  }else if (values.status === "99"){
      status = "Loading..."  
  }
  const utilized = new BigNumber(values.utilized)
  const tvl = new BigNumber(values.liquidity)
  const capacity = tvl === new BigNumber(0)  ? new BigNumber(0) : tvl.minus(utilized)
  const type = pool.type
  const apy = values !== undefined　? new BigNumber(values.apy) : new BigNumber(0)
  /*
  const [nextRebase, setNextRebase] = useState(0);
  const {account}: { account: string } = useWallet()
  const market = getMarketContract(lod,pool.pid)
  
  const fetchNextRebase = useCallback(async () => {
    if (!lod) return;
    const nextRebaseTimestamp = await getUnlock(market,account);
    if (nextRebaseTimestamp) {
      setNextRebase((Number(nextRebaseTimestamp[1]) + 604800)* 1000);
    } else {
      setNextRebase(0);
    }
  }, [setNextRebase, lod]);

  useEffect(() => {
    if (lod) {
      fetchNextRebase();
    }
  }, [fetchNextRebase, lod]);
  console.log(nextRebase)
  
  const renderer = (countdownProps: CountdownRenderProps) => {
    const { days ,hours, minutes, seconds } = countdownProps;
    const paddedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const paddedHours = hours < 10 ? `0${hours}` : hours;
    const paddedDays = days < 10 ? `0${days}` : days;
    return (
      <span>
        {paddedDays}:{paddedHours}:{paddedMinutes}:{paddedSeconds}
      </span>
    );
  };
    const renderer = (countdownProps: CountdownRenderProps) => {
    const { days ,hours } = countdownProps;
    const paddedHours = hours < 10 ? `0${hours}` : hours;
    const paddedDays = days < 10 ? `0${days}` : days;
    return (
      <span>
        {paddedDays}D {paddedHours}H
      </span>
    );
  };

  */
  const renderer = (countdownProps: CountdownRenderProps) => {
    const { days ,hours, minutes, seconds } = countdownProps;
    const paddedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const paddedHours = hours < 10 ? `0${hours}` : hours;
    const paddedDays = days < 10 ? `0${days}` : days;
    return (
      <span>
        {paddedDays}:{paddedHours}:{paddedMinutes}:{paddedSeconds}
      </span>
    );
  };


  const allowance = useAllowance(tokenContract,pool.vaultAddress)
  const { onApprove } = useApprove(tokenContract,pool.vaultAddress)
  
  const tokenBalance = useTokenBalance(tokenContract.options.address)
  //const unstakedxBalance = useTokenBalance(pool.marketAddress)
  const liquidity = useUnderlyingBalance(pool.pid)
  
  //const earnings = useEarnings(pool.pid)
    
  const unlock = useUnlock(pool.pid)
  
  const { onStake } = useStake(pool.pid, pool.tokenDecimal)
  const { onRequestWithdraw } = useRequestWithdraw(pool.pid,pool.tokenDecimal)
  const { onUnstake } = useUnstake(pool.pid,pool.tokenDecimal)
  const { onReward } = useReward(pool.pid)
  

  const [onPresentDeposit] = useModal(
    <DepositModal
      max={tokenBalance}
      decimal={pool.tokenDecimal}
      onConfirm={onStake}
      tokenName={pool.tokenSymbol}
      type={type}
    />,
  )

  const [onPresentWithdrawRequest] = useModal(
    <WithdrawRequestModal
      unstaked={liquidity.unstaked}
      staked={liquidity.staked}
      unlock={unlock}
      pool={pool}
      values={values}
      onRequest={onRequestWithdraw}
      onUnstake={onUnstake}
    />,
  )

  const [onPresentWithdraw] = useModal(
    <WithdrawModal
      max={new BigNumber(unlock.amount)}
      decimal={pool.tokenDecimal}
      onConfirm={onUnstake}
      tokenName={`LP Token`}
      capacity={new BigNumber(unlock.amount)}
    />,
  )
  
  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      const txHash = await onApprove()
      
      // user rejected tx or didn't go thru
      if (!txHash) {
        setRequestedApproval(false)
      }
    } catch (e) {
      console.log(e)
    }
  }, [onApprove, setRequestedApproval])

  return (
    <Card>
      <CardContent>
        <StyledCardContentInner>
        <StyledText>Underwriting</StyledText>
        <StyledDetail>Provide liquidity to earn premium</StyledDetail>
          <StyledCardContent>
            <StyledLabel >Type</StyledLabel >
            <StatusValue value={type}/>
          </StyledCardContent>
          {pool.type === "Index"?(
            <>
            <StyledCardContent>
              <StyledLabel >Leverage Rate</StyledLabel >
              <StatusValue value={values.leverage.toFixed(2)} decimals={2} suffix={'x'}/>
            </StyledCardContent>
            <StyledCardContent>
              <StyledLabel >Leverage Fee</StyledLabel >
              <StatusValue value={`3.00 %`} />
            </StyledCardContent>
            </>
          ):(
            <></>
          )}
          {pool.type === "CDS"?(
            <>
            <StyledCardContent>
              <StyledLabel >CDS Fee Rate</StyledLabel >
              <StatusValue value={`1.00 %`} />
            </StyledCardContent>
            </>
          ):(
            <></>
          )}
          <StyledCardContent>
            <StyledLabel >Underwriting APY</StyledLabel >
            <StatusValue value={apy.toFixed(2)} decimals={2}　suffix={'%'}/>
          </StyledCardContent>
          <StyledCardContent>
            <StyledLabel >TVL</StyledLabel >
            <StatusValue value={getDisplayBalance(tvl, pool.tokenDecimal)} prefix={'$'}/>
          </StyledCardContent>
          <StyledCardContent>
            <StyledLabel >Your Liquditiy</StyledLabel >
            <StatusValue value={getDisplayBalance(new BigNumber(liquidity.stakedVal) ,pool.tokenDecimal)}  prefix={'$'}/>
          </StyledCardContent>          
          <StyledCardActions>
            {!allowance.toNumber() ? (
              <Button
                disabled={requestedApproval}
                onClick={handleApprove}
                text={!requestedApproval ?`Approve ${pool.tokenSymbol}`:`Approving..`}
              />
            ) : (
              <>
                <Button
                  disabled={values.status !== '0'}　
                  text={'Deposit'}
                  onClick={onPresentDeposit}
                />
                <StyledActionSpacer />
                <Button
                  text={'Withdraw'}
                  disabled={new BigNumber(liquidity.amount).eq(new BigNumber(0)) ||values.status !== '0'}　
                  onClick={onPresentWithdrawRequest}
                />
                
                
              </>
            )}
          </StyledCardActions>
        </StyledCardContentInner>
      </CardContent>
    </Card>
  )
}

const StyledCardContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: inline;
  justify-content: space-between;
  width: 100%
`
const StyledCardActions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.theme.spacing[4]}px;
  width: 100%;
`

const StyledActionSpacer = styled.div`
  height: ${(props) => props.theme.spacing[4]}px;
  width: ${(props) => props.theme.spacing[4]}px;
`

const StyledCardContentInner = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`
const StyledLabel = styled.div`
  color: ${(props) => props.theme.color.grey[600]};
  font-size: h4
`

const StyledValue = styled.div`
  font-family: 'Roboto Mono', monospace;
  color: ${(props) => props.theme.color.grey[800]};
  font-size: h4;
  font-weight: 700;
  display: inline;
`

const StyledText = styled.div`
  align-items: center;
  color: ${props => props.theme.color.grey[900]};
  display: flex;
  font-size: 16px;
  font-weight: 700;
  height: 44px;
  justify-content: center;
  width:100%
`

const StyledDetail = styled.div`
  align-items: center;
  color: ${props => props.theme.color.grey[600]};
  display: flex;
  font-size: 14px;
  height: 34px;
  justify-content: center;
  width:100%
`

export default StakeFund

/*
<StyledCardContent>
            <StyledLabel >Underlying value</StyledLabel >
            
          </StyledCardContent>
          <StyledCardContent>
            <StyledLabel >Withdrawable LPT</StyledLabel >
            {withdrawable? (
              <StatusValue value={getBalanceNumber(new BigNumber(unlock.amount),pool.tokenDecimal)}  suffix={''}/>
            ):(
              <StatusValue value={getBalanceNumber(new BigNumber(0),pool.tokenDecimal)}  suffix={''}/>
            )}
          </StyledCardContent>
          <StyledCardContent>
            <StyledLabel >Withdraw wait</StyledLabel >
            <StyledValue>
              {requested?(
                <>{!expired?(
                <Countdown
                  date={new Date(wait)}
                  renderer={renderer}
                />
                ):(
                  <>
                  Expired
                  </>
                )}
                </>
              ):(
                <>
                Not Requested
                </>
              )}
            </StyledValue>
          </StyledCardContent>

<StyledCardContent>
            <StyledLabel >Status</StyledLabel >
            <StatusValue value={status}/>
          </StyledCardContent>
<StyledCardContent>
            <StyledLabel >Utilized vol.</StyledLabel >
            <StatusValue value={getDisplayBalance(utilized, pool.tokenDecimal)} prefix={'$'}/>
          </StyledCardContent>
*/