import { useCallback } from 'react'
import useLod from './useLod'
import { useWallet } from 'use-wallet'
import  {useTransactionAdder,useTransactionReceiver}  from './useTransaction'
import {useNoticeAdder} from './useNotices'
import { claim, getMarketContract } from '../lod/utils'

const useClaim = () => {
  const { account } = useWallet()
  const lod = useLod()
  const { onAddTransaction } = useTransactionAdder()
  const { onReceiveTransaction } = useTransactionReceiver()
  const { onAddNotice } = useNoticeAdder()
  
  const handleClaim = useCallback(async (id: string, pid: string) => {
    const market = await getMarketContract(lod, pid)  
    try {
      const txHash = await claim(
        market, 
        id, 
        account,
        (tx: any) => {
          onAddTransaction(tx)
        },
        (hash: any, receipt: any) => {
          onReceiveTransaction(hash,receipt)
        },
        (content: any) => {
          onAddNotice(content)
        }
      )
      return txHash
    } catch (e) {
      console.log(e)
      return false
    }
  }, [lod, account,onAddTransaction,onAddNotice,onReceiveTransaction])

  return { onClaim: handleClaim }
}

export default useClaim
