import { useContext } from 'react'
import { Context } from '../contexts/Notices'

export const useNoticeAdder = () => {
  const { onAddNotice } = useContext(Context)
  return { onAddNotice }
}

export const useNoticeRemover = () => {
  const { onRemoveNotice } = useContext(Context)
  return { onRemoveNotice }
}

export const useActiveNotices = () => {
  const { notices } = useContext(Context)
  const noticelist = Object.keys(notices)
    .map((txHash) => notices[txHash])
    .filter((notice) => notice.show && notice.type === "Notice")
  return noticelist
}
