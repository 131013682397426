 /* eslint-disable */
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import chef from '../../assets/img/chef.png'

const Logo: React.FC = () => {
  return (
    <StyledLogo to="/">
      <StyledText>
        InsureDAO
      </StyledText>      
    </StyledLogo>
  )
}

const StyledLogo = styled(Link)`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  min-height: 44px;
  min-width: 44px;
  padding: 0;
  text-decoration: none;
`
const StyledEmoji = styled.span.attrs({
  role: "img",
})`
  font-size: 24px;
`;

const StyledText = styled.span`
  color: ${(props) => props.theme.color.white};
  font-family: 'Reem Kufi', sans-serif;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.03em;
  max-width: 100px;
  margin-left: ${(props) => props.theme.spacing[2]}px;
`

const MasterChefText = styled.span`
  font-family: 'Redhat', sans-serif;
  margin-left: ${(props) => props.theme.spacing[1]}px;
  color: #f6c343;
  font-size: 15px;
`

export default Logo
