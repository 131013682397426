 /* eslint-disable */
import { useCallback, useEffect, useState } from 'react'
import { provider } from 'web3-core'

import BigNumber from 'bignumber.js'
import { useWallet } from 'use-wallet'
import { Contract } from 'web3-eth-contract'
import {
  getInsurances,
  getMarketAddress,
  getMyInsurance,
  getMyInsuranceCount,
  getMarketContract,
  getParemeterContract,
  convertUnixToDate
} from '../lod/utils'
import useLod from './useLod'
import useBlock from './useBlock'

/*
export interface StakedValue {
  id: BigNumber
  startTime: BigNumber
  status: BigNumber
}

uint256 id;
        uint256 startTime;
        uint256 endTime;
        uint256 amount;
        address insured;
        bool status;
*/
const useMyInsurances = () => {
  const [balances, setBalance] = useState([])
  const { account }: { account: string; ethereum: provider } = useWallet()
  const lod = useLod()
  const insurances = getInsurances(lod)
  const now = Date.now() / 1000
  const block = useBlock()
  /*
  const fetchAllValue = useCallback(async () => {
    let values:any[] = []
    for(let i = 0; i < insurances.length; i++){
      const marketContract = await getMarketContract(lod, insurances[i].pid)
      let insuranceData = await getMyInsurances(marketContract, insurances[i].pid, account)
      console.log(insuranceData) 
      values.push(insuranceData)
    }
    values.concat()
    console.log(values)
    setBalance(values)
  }, [account, lod, insurances])
  */

 const fetchAllValue = useCallback(async () => {
  let values:any[] = []
  for(let i = 0; i < insurances.length; i++){
    const marketContract = await getMarketContract(lod, insurances[i].pid)
    const count = await getMyInsuranceCount(marketContract, account)
    const parameterContract = await getParemeterContract(lod, insurances[i].pid)
    const marketAddress = await getMarketAddress(lod, insurances[i].pid)
    for(let ii = 0; ii < count; ii++){
      let insuranceData = await getMyInsurance(marketContract, parameterContract,ii, account,marketAddress)
        insuranceData.start = convertUnixToDate(insuranceData.startTime)
        insuranceData.end = convertUnixToDate(insuranceData.endTime)
        insuranceData.pid = insurances[i].pid
        insuranceData.tokenDecimal = insurances[i].tokenDecimal
        insuranceData.name = insurances[i].name
        insuranceData.icon = insurances[i].icon
        insuranceData.tokenSymbol = insurances[i].tokenSymbol
        insuranceData.target = insurances[i].IDs.find((x: any) => x.value.toLowerCase() === insuranceData.target)
        insuranceData.live = now < Number(insuranceData.endTime) + Number(insuranceData.grace) 
        insuranceData.unclaimed = insuranceData.status === true
        insuranceData.eligible = Number(insuranceData.incident) < Number(insuranceData.endTime) 
        values.push(insuranceData)
    }
  }
  if(values.length === 0){values=['nodata']}
  setBalance(values)
}, [account, lod, insurances])

  useEffect(() => {
    if (account &&  lod) {
      fetchAllValue()
    }
  }, [account, setBalance, lod,block])

  return balances
}

export default useMyInsurances
