import { black, green, grey, red, white } from './colors'

const theme = {
  borderRadius: 12,
  breakpoints: {
    mobile: 400,
  },
  color: {
    black,
    grey,
    primary: {
      light: grey[200],
      main: grey[500],
    },
    secondary: {
      main: green[500],
    },
    white,
    red,
    green,
    main: '#191937'
  },
  siteWidth: 1200,
  spacing: {
    1: 4,
    2: 8,
    3: 16,
    4: 24,
    5: 32,
    6: 48,
    7: 64,
  },
  topBarSize: 72,
  mediaWidth: {
    upToExtraSmall: 500,
    upToSmall: 600,
    upToMedium: 960,
    upToLarge: 1280
  }
}

export default theme
