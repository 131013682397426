 /* eslint-disable */
import { useCallback } from 'react'
import useLod from './useLod'
import { useWallet } from 'use-wallet'
import { getInsured, getMarketContract } from '../lod/utils'
import { BigNumber } from '../lod'
import  {useTransactionAdder,useTransactionReceiver}  from './useTransaction'
import {useNoticeAdder} from './useNotices'


const useInsure = (pid:number, decimal:number) => {
  const { account } = useWallet()
  const lod = useLod()
  const { onAddTransaction } = useTransactionAdder()
  const { onReceiveTransaction } = useTransactionReceiver()
  const { onAddNotice } = useNoticeAdder()
  const market = getMarketContract(lod,pid)
  
  const handleInsure = useCallback(async (amount: number, item: string ,maxCost: string, spanFormat: string) => {
    try {
      const txHash = await getInsured(
        market, 
        new BigNumber(amount).times(new BigNumber(10).pow(decimal)).toString(), 
        maxCost, 
        spanFormat, 
        item,account,
        (tx: any) => {
          onAddTransaction(tx)
        },
        (hash: any, receipt: any) => {
          onReceiveTransaction(hash,receipt)
        },
        (content: any) => {
          onAddNotice(content)
        }
      )
      return txHash
    } catch (e) {
      console.log(e)
      return false
    }
  }, [account, market])

  return { onInsure: handleInsure }
}

export default useInsure
