 /* eslint-disable */
import { useCallback } from 'react'
import useLod from './useLod'
import { useWallet } from 'use-wallet'
import { harvest, getMinterContract,getGaugeAddress } from '../lod/utils'
import  {useTransactionAdder,useTransactionReceiver}  from './useTransaction'
import {useNoticeAdder} from './useNotices'

const useReward = (pid: number) => {
  const { account } = useWallet()
  const lod = useLod()
  const market = getMinterContract(lod)
  const gauge = getGaugeAddress(lod,pid)
  const { onAddTransaction } = useTransactionAdder()
  const { onReceiveTransaction } = useTransactionReceiver()
  const { onAddNotice } = useNoticeAdder()

  const handleReward = useCallback(async () => {
    try {
      const txHash = await harvest(
        market, 
        gauge,
        account,
        (tx: any) => {
          onAddTransaction(tx)
        },
        (hash: any, receipt: any) => {
          onReceiveTransaction(hash,receipt)
        },
        (content: any) => {
          onAddNotice(content)
        }
      )
      return txHash
    } catch (e) {
      return false
    }
  }, [market, pid, account])
  return { onReward: handleReward }
}

export default useReward
