 /* eslint-disable */
import BigNumber from 'bignumber.js'
import React, { useCallback, useState,useEffect } from 'react'
import styled from 'styled-components'
import { Contract } from 'web3-eth-contract'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import CardIcon from '../../../components/CardIcon'
import Separator from '../../../components/Separator'
import { AddIcon } from '../../../components/icons'
import Spacer from '../../../components/Spacer'
import StatusValue from '../../../components/StatusValue'
import useAllowance from '../../../hooks/useAllowance'
import useApprove from '../../../hooks/useApprove'
import useModal from '../../../hooks/useModal'
import useStake from '../../../hooks/useStake'
import useStakedBalance from '../../../hooks/useUnderlyingBalance'
import useTokenBalance from '../../../hooks/useTokenBalance'
import useInsure from '../../../hooks/useInsure'
import { getFullDisplayBalance } from '../../../utils/formatBalance'
import { convertUnixToDate,getItemLabel } from '../../../lod/utils'
import useLod from '../../../hooks/useLod'
interface StakeProps {
  insurance: any
  tokenContract: Contract
  amount: number,
  span: number,
  quote: BigNumber
  item:any
  limit?: boolean
  min: number
}

const QuoteDisplay: React.FC<StakeProps> = ({insurance, tokenContract, amount, span, quote,item,min }) => {
  const [requestedApproval, setRequestedApproval] = useState(false)
  const [pendingTx, setPendingTx] = useState(false)
  const now = new Date().getTime() / 1000
  const expires = convertUnixToDate(now + span*86400)
  const spanFormat = (span*86400).toFixed(0)
  const maxCost = quote.multipliedBy(2).toString()
  const allowance = useAllowance(tokenContract,insurance.vaultAddress)
  const tokenBalance = useTokenBalance(tokenContract.options.address)
  const stakedBalance = useStakedBalance(insurance.pid)
  const { onApprove } = useApprove(tokenContract,insurance.vaultAddress)
  const { onInsure } = useInsure(insurance.pid, insurance.tokenDecimal)  
  const lod = useLod()
  
  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      const txHash = await onApprove()
      // user rejected tx or didn't go thru
      if (!txHash) {
        setRequestedApproval(false)
      }
    } catch (e) {
      console.log(e)
      setRequestedApproval(false)
    }
  }, [onApprove, setRequestedApproval])

  const handleBuy = useCallback(async () => {
    try {
      
      setPendingTx(true)
      await onInsure(amount, item ,maxCost, spanFormat)
      // user rejected tx or didn't go thru
      setPendingTx(false)
    } catch (e) {
      console.log(e)
    }
  }, [onInsure, setRequestedApproval,amount, maxCost, spanFormat])

  return (
    <Card>
      <CardContent>
        <StyledCardContentInner>
        <StyledText>Quote summary</StyledText>
          <StyledCardContent>
            <StyledLabel >Protocol</StyledLabel >
            <StyledLink target="_blank"　href={insurance.url} >{insurance.name}</StyledLink>
          </StyledCardContent>
          
          <StyledCardContent>
            <StyledLabel >Payout Rule</StyledLabel >
            <StyledLink target="_blank"　href={insurance.payout} >Link</StyledLink>
          </StyledCardContent>
          <Separator/>
          <StyledCardContent>
            <StyledLabel >Expires</StyledLabel >
            <StatusValue value={expires} />
          </StyledCardContent>
          <StyledCardContent>
            <StyledLabel >Cover cost</StyledLabel >
            <StatusValue value={getFullDisplayBalance(quote, insurance.tokenDecimal)} suffix={insurance.tokenSymbol}/>
          </StyledCardContent>
          <StyledCardContent>
            <StyledNoteText>The quote here may change as cost rate dynamically changes.</StyledNoteText>
          </StyledCardContent>
          <StyledCardContent>            
              {quote.gt(tokenBalance)?(
                <StyledCautionText>
                  {`Insufficient ${insurance.tokenSymbol} balance`}
                </StyledCautionText>
              ):(
                <></>
              )}
          </StyledCardContent>
          <StyledCardActions>
            {!allowance.toNumber() || allowance.toNumber() < quote.toNumber()? (
              <>
              <Button
                disabled={requestedApproval}
                onClick={handleApprove}
                text={!requestedApproval ?`Approve ${insurance.tokenSymbol}`:`Approving..`}
              />
              </>
            ) : (
              <>
              <Button
                disabled={pendingTx || amount === 0 || span < min ||quote.gt(tokenBalance)}
                onClick={handleBuy}
                text={pendingTx ?'Pending Confirmation..':`Buy insurance`}
              />
              </>
            )}
          </StyledCardActions>
        </StyledCardContentInner>
      </CardContent>
    </Card>
  )
}

const StyledCardContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: inline;
  justify-content: space-between;
  width: 100%
`
const StyledCardActions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.theme.spacing[4]}px;
  width: 100%;
`

const StyledActionSpacer = styled.div`
  height: ${(props) => props.theme.spacing[4]}px;
  width: ${(props) => props.theme.spacing[4]}px;
`

const StyledCardContentInner = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`
const StyledLabel = styled.div`
  color: ${(props) => props.theme.color.grey[600]};
  font-size: h4
`

const StyledText = styled.div`
  align-items: center;
  color: ${props => props.theme.color.grey[900]};
  display: flex;
  font-size: 16px;
  font-weight: 700;
  height: 44px;
  justify-content: center;
  width:100%
`

const StyledNoteText = styled.div`
  align-items: center;
  color: ${props => props.theme.color.grey[300]};
  display: flex;
  font-size: 14px;
  font-weight: 700;
  margin-top: 4px;
  width:100%
  justify-content: flex-start;
`

const StyledCautionText = styled.div`
  align-items: center;
  color: ${props => props.theme.color.red[500]};
  display: flex;
  font-size: 14px;
  font-weight: 700;
  margin-top: 4px;
  width:100%
  justify-content: flex-start;
`
const StyledLink = styled.a`
  font-family: 'Roboto Mono', monospace;
  color: ${(props) => props.theme.color.grey[800]};
  font-size: h4;
  font-weight: 700;
  display: inline;

  &:hover {
    color: ${(props) => props.theme.color.grey[200]};
  }
`
export default QuoteDisplay

/*

          <StyledCardContent>
            <StyledLabel >Cover amount</StyledLabel >
            <StatusValue value={getBalanceNumber(new BigNumber(amount), 0)} suffix={insurance.tokenSymbol}/>
          </StyledCardContent>
          <StyledCardContent>
            <StyledLabel >Cover days</StyledLabel >
            <StatusValue value={getBalanceNumber(new BigNumber(span),0)} decimals={0} suffix={'days'}/>
          </StyledCardContent>

*/