 /* eslint-disable */
import React, { useCallback,useState } from 'react'
import styled from 'styled-components'
import { useWallet } from 'use-wallet'
import useTokenBalance from '../../../hooks/useTokenBalance'
import useLod from '../../../hooks/useLod'
import {useMint,useMintCheck} from '../../../hooks/useFaucet'
import { getInsureAddress } from '../../../lod/utils'
import { getBalanceNumber } from '../../../utils/formatBalance'
import Button from '../../Button'
import CardIcon from '../../CardIcon'
import Label from '../../Label'
import Modal, { ModalProps } from '../../Modal'
import ModalActions from '../../ModalActions'
import ModalContent from '../../ModalContent'
import ModalTitle from '../../ModalTitle'
import Spacer from '../../Spacer'
import Value from '../../Value'
//import AES from 'crypto-js'

const AccountModal: React.FC<ModalProps> = ({ onDismiss }) => {
  const [pendingTx, setPendingTx] = useState(false)

  const { account, reset } = useWallet()
  
  const { onMint } = useMint()
  const minted = useMintCheck();
  const crypto = require('crypto');
  const ENCRYPTION_KEY = "HH95XH7sYAbznRBJSUE9W8RQxzQIGSas" // 32Byte. このまま利用しないこと！
  const BUFFER_KEY = "RfHBdAR5RJHqp5ge" // 16Byte. このまま利用しないこと！
  const ENCRYPT_METHOD = "aes-256-cbc" // 暗号化方式
  const ENCODING = "hex" // 暗号化時のencoding
  
  function getEncryptedString(raw: any) {
    let iv = Buffer.from(BUFFER_KEY)
    let cipher = crypto.createCipheriv(ENCRYPT_METHOD, Buffer.from(ENCRYPTION_KEY), iv)
    let encrypted = cipher.update(raw)
  
    encrypted = Buffer.concat([encrypted, cipher.final()])
  
    return encrypted.toString(ENCODING)
  }
  /*
  const test = getEncryptedString(account)
  function getDecryptedString(encrypted: any) {
    let iv = Buffer.from(BUFFER_KEY)
    let encryptedText = Buffer.from(encrypted, ENCODING)
    let decipher = crypto.createDecipheriv(ENCRYPT_METHOD, Buffer.from(ENCRYPTION_KEY), iv)
    let decrypted = decipher.update(encryptedText)
  
    decrypted = Buffer.concat([decrypted, decipher.final()])
  
    return decrypted.toString()
  }
  console.log(getEncryptedString(account),getDecryptedString(test))
  */

  const tweet = `I just participated in InsureDAO testnet.%0ATry testnet and get airdrop (☞ ͡° ͜ʖ ͡°)☞  insuredao.fi%0A` + getEncryptedString(account) +`%0A%23InsureDAO%20%23DeFi%20`
  
  const handleSignOutClick = useCallback(() => {
    onDismiss!()
    reset()
  }, [onDismiss, reset])
  
  const lod = useLod()
  const lodBalance = useTokenBalance(getInsureAddress(lod))

  return (
    <Modal>
      <ModalTitle text="My Account" />
      <ModalContent>
        <Spacer />

        <div style={{ display: 'flex' }}>
          <StyledBalanceWrapper>
            <CardIcon>
              <StyledImg src={require(`../../../assets//img/logo.png`)} />
            </CardIcon>
            <StyledBalance>
              <Value value={getBalanceNumber(lodBalance,18)} />
              <Label text="INSURE Balance" />
            </StyledBalance>
          </StyledBalanceWrapper>
        </div>

        <Spacer />
        <Button
          href={`https://rinkeby.etherscan.io/address/${account}`}
          text="View on Etherscan"
          variant="secondary"
        />
        
        <Spacer />
        <Button
          onClick={() => {
            window.open(`https://twitter.com/intent/tweet?text=${tweet}`, "_blank")
          }}
          text="Tweet"
          variant="secondary"
        />
        <StyledText>Tweet here to participate in test</StyledText>
        {minted?(
          <Button
          disabled={true}　
          text={'You have already claimed USDC faucet'}
          variant="secondary"
          />
        ):(
          <Button
            disabled={pendingTx}　
            text={pendingTx ? 'Claiming..' : 'Get USDC Faucet'}
            onClick={async() => {
              setPendingTx(true)
              await onMint()
              setPendingTx(false)
            }}
            variant="secondary"
          />
        )}
        
        <StyledText>You can claim faucet only once</StyledText>
      </ModalContent>
      <ModalActions>
        <Button onClick={onDismiss} text="Cancel" />
      </ModalActions>
    </Modal>
  )
}

const StyledBalance = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`

const StyledBalanceWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.spacing[4]}px;
`
const StyledText = styled.div`
  align-items: center;
  color: ${props => props.theme.color.grey[400]};
  display: flex;
  font-size: 14px;
  font-weight: 700;
  height: 44px;
  justify-content: center;
`

const StyledImg = styled.img`
  alt: '🤔'
  height: 30px;
  width: 30px;
`
export default AccountModal

/*

<Spacer />
        <Button
          onClick={handleSignOutClick}
          text="Sign out"
          variant="secondary"
        />
*/