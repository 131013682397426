 /* eslint-disable */
import BigNumber from 'bignumber.js'
import React, { useCallback, useMemo, useState } from 'react'
import Button from '../../../components/Button'
import Modal, { ModalProps } from '../../../components/Modal'
import ModalActions from '../../../components/ModalActions'
import ModalTitle from '../../../components/ModalTitle'
import TokenInput from '../../../components/TokenInput'
import { getFullDisplayBalance,getBalanceNumber } from '../../../utils/formatBalance'

interface WithdrawModalProps extends ModalProps {
  max: BigNumber
  decimal: number
  onConfirm: (amount: string) => void
  tokenName?: string
  capacity?: any
}

const WithdrawModal: React.FC<WithdrawModalProps> = ({
  onConfirm,
  decimal,
  onDismiss,
  max,
  tokenName = '',
  capacity
}) => {
  const [val, setVal] = useState('')
  const [pendingTx, setPendingTx] = useState(false)

  const fullBalance = useMemo(() => {
    if(capacity === undefined || capacity > max){
      
      return getBalanceNumber(max,decimal)
    }else{
      
      return getBalanceNumber(capacity,decimal)
    }
  }, [max])

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setVal(e.currentTarget.value)
    },
    [setVal],
  )

  const handleSelectMax = useCallback(() => {
    setVal(fullBalance.toString())
  }, [fullBalance, setVal])

  return (
    <Modal>
      <ModalTitle text={`Unstake LP Token`} />
      <TokenInput
        onSelectMax={handleSelectMax}
        onChange={handleChange}
        value={val}
        max={fullBalance}
        symbol={'LP Token'}
        available={true}
      />
      <ModalActions>
        <Button text="Cancel" variant="secondary" onClick={onDismiss} />
        <Button
          disabled={pendingTx||Number(val) ===0}
          text={pendingTx ? 'Pending Confirmation' : 'Confirm'}
          onClick={async () => {
            setPendingTx(true)
            await onConfirm(val)
            setPendingTx(false)
            onDismiss()
          }}
        />
      </ModalActions>
    </Modal>
  )
}

export default WithdrawModal
