 /* eslint-disable */
import { options } from 'numeral'
import React, { Children } from 'react'
import styled from 'styled-components'
import { StyledButton } from '../../views/DAO/components/Mining'

export interface SelectProps {
  endAdornment?: React.ReactNode,
  onChange: (e: React.FormEvent<HTMLSelectElement>) => void,
  placeholder?: string,
  startAdornment?: React.ReactNode,
  value?: string,
  error?: boolean,
  options: { value: string, label: string }[]
}


const Select: React.FC<SelectProps> = ({
  endAdornment,
  onChange,
  placeholder,
  startAdornment,
  value,
  error,
  options
}) => {
  return (
    <StyledInputWrapper error={error}>
      <StyledSelect onChange={onChange} >
        
      {options.map((item,i) => {
        return(
        <StyledOption key={i} value={item.value}>
          {item.label}
        </StyledOption>
        )
      })}
      </ StyledSelect>
    </StyledInputWrapper>
  )
}

export interface ReportError{
  error: boolean
}

const StyledInputWrapper = styled.div<ReportError>`
  align-items: center;
  background-color: ${props => props.theme.color.white};
  border-radius: ${props => props.theme.borderRadius}px;
  border-style: solid;
  border-width: 0.5px;
  border-color:${props => props.error? props.theme.color.red[500] :props.theme.color.white};
  display: flex;
  height: 56px;
  padding: 0 ${props => props.theme.spacing[3]}px;
`

const StyledSelect = styled.select`
  background: none;
  border: 0;
  color: ${props => props.theme.color.grey[600]};
  font-size: 18px;
  flex: 1;
  height: 56px;
  margin: 0;
  display: flex;
  width:100%;
  padding: 0;
  outline: none;
`
const StyledOption = styled.option`
  background: none;
  border: 0;
  color: ${props => props.theme.color.grey[600]};
  font-size: 18px;
  flex: 1;
  margin: 0;
  display: flex;
  width:40%;
  padding: 0;
  outline: none;
`

export default Select