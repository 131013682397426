import React from 'react'
import styled from 'styled-components'

export interface InputProps {
  endAdornment?: React.ReactNode,
  onChange: (e: React.FormEvent<HTMLInputElement>) => void,
  placeholder?: string,
  startAdornment?: React.ReactNode,
  value: string,
  error?: boolean
}

const Input: React.FC<InputProps> = ({
  endAdornment,
  onChange,
  placeholder,
  startAdornment,
  value,
  error
}) => {
  return (
    <StyledInputWrapper error={error}>
      {!!startAdornment && startAdornment}
      <StyledInput  placeholder={placeholder} value={value} onChange={onChange} />
      {!!endAdornment && endAdornment}
    </StyledInputWrapper>
  )
}

export interface ReportError{
  error: boolean
}

const StyledInputWrapper = styled.div<ReportError>`
  align-items: center;
  background-color: ${props => props.theme.color.white};
  border-radius: ${props => props.theme.borderRadius}px;
  border-style: solid;
  border-width: 0.5px;
  border-color:${props => props.error? props.theme.color.red[500] :props.theme.color.white};
  display: flex;
  height: 56px;
  padding: 0 ${props => props.theme.spacing[3]}px;
`

const StyledInput = styled.input`
  background: none;
  border: 0;
  color: ${props => props.theme.color.grey[600]};
  font-size: 18px;
  flex: 1;
  height: 56px;
  margin: 0;
  display: flex;
  width:100%;
  padding: 0;
  outline: none;
`

export default Input