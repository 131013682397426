 /* eslint-disable */
import { useCallback } from 'react'

import useLod from './useLod'
import { useWallet } from 'use-wallet'
import { provider } from 'web3-core'
import { Contract } from 'web3-eth-contract'
import  {useTransactionAdder,useTransactionReceiver}  from './useTransaction'
import {useNoticeAdder} from './useNotices'
import { approve, getMarketAddress } from '../lod/utils'

const useApprove = (tokenContract: Contract, address:any) => {
  const { account }: { account: string; ethereum: provider } = useWallet()
  const lod = useLod()
  const { onAddTransaction } = useTransactionAdder()
  const { onReceiveTransaction } = useTransactionReceiver()
  const { onAddNotice } = useNoticeAdder()
  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(
        tokenContract, 
        address, 
        account,
        (tx: any) => {
          onAddTransaction(tx)
        },
        (hash: any, receipt: any) => {
          onReceiveTransaction(hash,receipt)
        },
        (content: any) => {
          onAddNotice(content)
        }
      )
      return tx
    } catch (e) {
      return false
    }
  }, [account, tokenContract, address])

  return { onApprove: handleApprove }
}

export default useApprove
