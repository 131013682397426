 /* eslint-disable */
import { useCallback, useEffect, useState } from 'react'

import BigNumber from 'bignumber.js'
import { useWallet } from 'use-wallet'

import { getUnderlying, getMarketContract,getGaugeContract } from '../lod/utils'
import { getBalance } from '../utils/erc20'
import useLod from './useLod'
import useBlock from './useBlock'

const useUnderlyingBalance = (pid: number) => {
  const [balance, setBalance] = useState({
    stakedVal: 0,
    unstaked:0,
    staked:0,
    amount:0
  })
  const { account }: { account: string } = useWallet()
  const lod = useLod()
  const market = getMarketContract(lod,pid)
  const gauge = getGaugeContract(lod,pid)
  const block = useBlock()

  const fetchBalance = useCallback(async () => {
    const data = await getUnderlying(market, gauge ,account)
    setBalance(data)
  }, [market, account])

  useEffect(() => {
    if (account && lod) {
      fetchBalance()
    }
  }, [account, setBalance, block, lod, fetchBalance])

  return balance
}

export default useUnderlyingBalance
