 /* eslint-disable */
import BigNumber from 'bignumber.js'
import React, { useCallback, useMemo, useState,useEffect } from 'react'
import styled from 'styled-components'
import Button from '../../../components/Button'
import Modal, { ModalProps } from '../../../components/Modal'
import ModalActions from '../../../components/ModalActions'
import ModalTitle from '../../../components/ModalTitle'
import TokenInput from '../../../components/TokenInput'
import ModalContent from '../../../components/ModalContent'
import { getDisplayBalance,getBalanceNumber } from '../../../utils/formatBalance'

interface DepositModalProps extends ModalProps {
  max: BigNumber
  decimal: number
  onConfirm: (amount: string) => void
  unlock: any
  params: any
  tokenName?: string
  type?: string
}

const DepositModal: React.FC<DepositModalProps> = ({
  max,
  decimal,
  onConfirm,
  onDismiss,
  tokenName = '',
  type,
  unlock,
  params
}) => {
  const [val, setVal] = useState('')
  const [pendingTx, setPendingTx] = useState(false)

  let now = Math.floor(Date.now() / 1000);
  let expired = now > (Number(params.withdrawGrace) + Number(unlock.timestamp))
  let requested = 0 < unlock.amount &&!expired　? new BigNumber(unlock.amount):new BigNumber(0) 
  let safeStake = max.minus(requested)
  
  useEffect(() => {  
    expired = now > (Number(params.withdrawGrace) + Number(unlock.timestamp))
    requested = 0 < unlock.amount &&!expired　? new BigNumber(unlock.amount):new BigNumber(0) 
    safeStake = max.minus(requested)
  }, [unlock, params, now])  

  const fullBalance = useMemo(() => {
    return getBalanceNumber(max,decimal)
  }, [max])

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setVal(e.currentTarget.value)
    },
    [setVal],
  )

  const handleSelectMax = useCallback(() => {
    setVal(fullBalance.toString())
  }, [fullBalance, setVal])
  
  return (
    <Modal>
      <ModalTitle text={`Stake ${tokenName}`} />
      
        <StyledMaxText>{getDisplayBalance(new BigNumber(fullBalance),0)} LP Token Available</StyledMaxText>
        <StyledMaxText>({getDisplayBalance(new BigNumber(requested),decimal)} LP Token Requested)</StyledMaxText>
        <StyledActionSpacer/>
        <TokenInput
          value={val}
          onSelectMax={handleSelectMax}
          onChange={handleChange}
          max={fullBalance}
          symbol={tokenName}
          available={false}
        />
        <StyledActionSpacer/>
        <StyledText>If you stake more than {getDisplayBalance(new BigNumber(safeStake),decimal)}, your request/withdrawable amount will decrease.</StyledText>

      <ModalActions>
        <Button text="Cancel" variant="secondary" onClick={onDismiss} />
        <Button
          disabled={pendingTx　|| Number(val) ===0 }
          text={pendingTx ? 'Pending Confirmation..' : 'Confirm'}
          onClick={async () => {
            setPendingTx(true)
            await onConfirm(val)
            
            setPendingTx(false)
            onDismiss()
          }}
        />
      </ModalActions>
    </Modal>
  )
}

export default DepositModal

const StyledText = styled.div`
  align-items: center;
  color: ${props => props.theme.color.grey[400]};
  display: flex;
  font-size: 14px;
  font-weight: 700;
  height: 44px;
  justify-content: center;
`
const StyledDetail = styled.div`
  align-items: center;
  color: ${props => props.theme.color.grey[800]};
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 44px;
  justify-content: center;
`
const StyledMaxText = styled.div`
  align-items: center;
  color: ${props => props.theme.color.grey[400]};
  display: flex;
  font-size: 14px;
  font-weight: 700;
  justify-content: flex-end;
`
const StyledActionSpacer = styled.div`
  height: ${(props) => props.theme.spacing[2]}px;
  width: ${(props) => props.theme.spacing[2]}px;
`