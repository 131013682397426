import { useContext } from 'react'
import { Context } from '../contexts/Transactions'

export const useTransactionAdder = () => {
  const { onAddTransaction } = useContext(Context)
  return { onAddTransaction }
}

export const useTransactionReceiver = () => {
  const { onReceiveTransaction } = useContext(Context)
  return { onReceiveTransaction }
}
