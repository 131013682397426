 /* eslint-disable */
import { useCallback, useEffect, useState } from 'react'
import { provider } from 'web3-core'

import BigNumber from 'bignumber.js'
import { useWallet } from 'use-wallet'
import { Contract } from 'web3-eth-contract'
import {
  getInsurances,
  getAllPoolStatus,
} from '../lod/utils'
import useLod from './useLod'
import useBlock from './useBlock'

export interface StakedValue {
  utilized: BigNumber
  liquidity: BigNumber
  status: string
  premium: BigNumber
  apy: BigNumber
}

const useAllPoolStatus = () => {
  const [balances, setBalance] = useState([] as Array<StakedValue>)
  const { account }: { account: string; ethereum: provider } = useWallet()
  const lod = useLod()
  const insurances = getInsurances(lod)
  const fetchAllValue = useCallback(async () => {
    const balances: Array<StakedValue> = await Promise.all(
      insurances.map(
        ({
          pid,
          type
        }: {
          pid: number,
          type: string
        }) =>
        getAllPoolStatus(
            lod,
            pid,
            type
          ),
      ),
    )
  
    setBalance(balances)
  }, [account, lod])

  useEffect(() => {
    if (account &&  lod) {
      fetchAllValue()
    }
  }, [account, setBalance, lod])

  return balances
}

export default useAllPoolStatus
