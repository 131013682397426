 /* eslint-disable */
import React from 'react'
import { Route, Switch, useRouteMatch,Redirect } from 'react-router-dom'
import { useWallet } from 'use-wallet'

import chef from '../../assets/img/chef.png'

import Button from '../../components/Button'
import Page from '../../components/Page'
import PageHeader from '../../components/PageHeader'
import WalletProviderModal from '../../components/WalletProviderModal'

import useModal from '../../hooks/useModal'

import Pool from '../Pool'

import PoolCards from './components/PoolCards'

const Pools: React.FC = () => {
  const { path } = useRouteMatch()
  const { account } = useWallet()
  const [onPresentWalletProviderModal] = useModal(<WalletProviderModal />)
  
  return (
    <Switch>
      <Page>
        {!!account ? (
          <>
            <Route exact path={path}>
              <PageHeader
                subtitle="Sell insurances & Earn premiums"
                title="Underwriting"
              />
              <PoolCards />
            </Route>
            <Route  path={`${path}/:poolId`}>
              <Pool />
            </Route>
          </>
        ) : (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={onPresentWalletProviderModal}
              text="Connect Wallet"
            />
          </div>
        )}
      </Page>
    </Switch>
  )
}

export default Pools
