/* eslint-disable */
import React, { Fragment, useCallback, useState } from 'react'
import styled from 'styled-components'
import { useActiveNotices } from '../../hooks/useNotices'
import PopupItem from './PopupItem'
import { AutoColumn } from '../Column'

const MobilePopupWrapper = styled.div<{ height: string | number }>`
  position: relative;
  max-width: 100%;
  height: ${({ height }) => height};
  margin: ${({ height }) => (height ? '0 auto;' : 0)};
  margin-bottom: ${({ height }) => (height ? '20px' : 0)}};
`

const MobilePopupInner = styled.div`
  height: 99%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none;
  }
`
const FixedPopupColumn = styled(AutoColumn)`
  position: fixed;
  
  top: 64px;
  right: 1rem;
  max-width: 312px !important;
  width: 100%;
  z-index: 2;
`

export default function Notice() {
  // get all popup
  const activeNotices = useActiveNotices()
  return (
    <>
     <FixedPopupColumn gap="20px">
        {activeNotices.map(item => (
          <PopupItem key={item.hash} content={item} />
        ))}
     </FixedPopupColumn>
    
    </>
  )
}

/*


 <MobilePopupWrapper height={activeNotices.length > 0 ? 'fit-content' : 0}>
        <MobilePopupInner>
          {activeNotices // reverse so new items up front
            .slice(0)
            .reverse()
            .map((item:any) => (
              <PopupItem key={item.hash} content={item} />
            ))}
        </MobilePopupInner>
      </MobilePopupWrapper>
*/