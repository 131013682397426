 /* eslint-disable */
import { useCallback, useEffect, useState } from 'react'
import { provider } from 'web3-core'

import BigNumber from 'bignumber.js'
import { useWallet } from 'use-wallet'
import { Contract } from 'web3-eth-contract'
import {
  getInsurances,
  getAllPoolStatus,
  getUnderlying,
  getMarketContract,
  getGaugeContract,
  getUnlock
} from '../lod/utils'
import useLod from './useLod'
import useBlock from './useBlock'

/*
export interface StakedValue {
  id: BigNumber
  startTime: BigNumber
  status: BigNumber
}

uint256 id;
        uint256 startTime;
        uint256 endTime;
        uint256 amount;
        address insured;
        bool status;
*/
const useMyPools = () => {
  const [balances, setBalance] = useState([])
  const { account }: { account: string; ethereum: provider } = useWallet()
  const lod = useLod()
  const insurances = getInsurances(lod)
  /*
  const fetchAllValue = useCallback(async () => {
    let values:any[] = []
    for(let i = 0; i < insurances.length; i++){
      const marketContract = await getMarketContract(lod, insurances[i].pid)
      let insuranceData = await getMyInsurances(marketContract, insurances[i].pid, account)
      console.log(insuranceData) 
      values.push(insuranceData)
    }
    values.concat()
    console.log(values)
    setBalance(values)
  }, [account, lod, insurances])
  */
  /*
 const fetchAllValue = useCallback(async () => {
  let values:any[] = []
  for(let i = 0; i < insurances.length; i++){
    const marketContract = await getMarketContract(lod, insurances[i].pid)
    const gauge = getGaugeContract(lod,insurances[i].pid)
    let poolData:any = await getMyPool(marketContract, gauge,account)
    if(poolData.balance !== 0){
      poolData.id = insurances[i].id
      poolData.tokenDecimal = insurances[i].tokenDecimal
      poolData.name = insurances[i].name
      poolData.icon = insurances[i].icon
      poolData.tokenSymbol = insurances[i].tokenSymbol
      values.push(poolData)
    }
  }
  */
  const fetchAllValue = useCallback(async () => {
    let values:any[] = []
    for(let i = 0; i < insurances.length; i++){
      
      const marketContract = await getMarketContract(lod, insurances[i].pid)
      const gauge =  await getGaugeContract(lod,insurances[i].pid)
      let underlying = await getUnderlying(marketContract, gauge,account)
      let poolData:any 
      let unlock
      if(underlying.stakedVal !== 0){
        poolData = await  getAllPoolStatus(lod, insurances[i].pid, insurances[i].type, 0) 
        unlock = await getUnlock(marketContract,account)
        if(unlock.amount > 0){
          let now = Math.floor(Date.now() / 1000);
          let wait = (Number(unlock.timestamp) + Number(poolData.lockup) )*1000
          let limit = Number(poolData.withdrawGrace)*1000 + wait 
          let expired = now > (Number(poolData.withdrawGrace) + Number(unlock.timestamp))
          let withdrawable = now > Number(poolData.lockup) + Number(unlock.timestamp) && expired === false
          let requested = 0 < unlock.amount &&!expired　? unlock.amount:0 
          if(withdrawable && poolData.status !== "1"){poolData.status = "Withdrawable"}else if(requested > 0&& poolData.status !== "1"){poolData.status = "Requested"}else if (poolData.status !== "1"){poolData.status = "-"}else{poolData.status = "Paying out"}
          
        }else{
          poolData.status = "-"
        }
        poolData.total = underlying.amount
        poolData.value = underlying.stakedVal
        poolData.id = insurances[i].id
        poolData.tokenDecimal = insurances[i].tokenDecimal
        poolData.name = insurances[i].name
        poolData.icon = insurances[i].icon
        poolData.type = insurances[i].type
        poolData.tokenSymbol = insurances[i].tokenSymbol
        values.push(poolData)
      }
    }
  if(values.length === 0){values=['nodata']}
    setBalance(values)
  }, [account, lod, insurances])

  useEffect(() => {
    if (account &&  lod) {
      fetchAllValue()
    }
  }, [account, setBalance, lod])

  return balances
}

export default useMyPools
