 /* eslint-disable */
import { useCallback, useEffect, useState } from 'react'
import { provider } from 'web3-core'

import BigNumber from 'bignumber.js'
import { useWallet } from 'use-wallet'

import { getAllFarmStatus } from '../lod/utils'
import useLod from './useLod'
import useBlock from './useBlock'

export interface StakedValue {
  staked: BigNumber
  unclaimed: BigNumber
  status: string
  lowAPY: number
  highAPY: number
  working_balance: BigNumber
}

const useFarming = (type: string, pid: number) => {
  const [balance, setBalance] = useState({
    staked: new BigNumber(0),
    unclaimed: new BigNumber(0),
    working_balance: new BigNumber(0)
  } as StakedValue)
  const {account}: { account: string } = useWallet()
  const lod = useLod()
  const block = useBlock()

  const fetchBalance = useCallback(async () => {
    const balance = await getAllFarmStatus(lod, pid, account)
    setBalance(balance)
  }, [account,  pid])

  useEffect(() => {
    if (account &&  lod) {
      fetchBalance()
    }
  }, [account, block, setBalance, lod, fetchBalance])

  return balance
}

export default useFarming
